//AUTHENTICATION
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ARCHIVE_USER = 'ARCHIVE_USER';

//ADMIN
export const ADD_NEW_ADMIN = 'ADD_NEW_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const CLEAR_SELECTED_ADMIN = 'CLEAR_SELECTED_ADMIN';

//FINAL
export const SELECT_FINAL = 'SELECT_FINAL';
export const CLEAR_FINAL = 'CLEAR_FINAL';
export const GET_FINAL = 'GET_FINAL';
export const GET_ALL_FINALS = 'GET_ALL_FINALS';
export const IS_FINAL_ACTIVE = 'IS_FINAL_ACTIVE';
export const CREATE_FINAL = 'CREATE_FINAL';
export const UPDATE_FINAL = 'UPDATE_FINAL';
export const ARCHIVE_FINAL = 'ARCHIVE_FINAL';
export const DELETE_FINAL = 'DELETE_FINAL';
export const RESET_FINAL_PARTICIPANTS = 'RESET_FINAL_PARTICIPANTS';
export const IMPORT_LIST = 'IMPORT_LIST';
export const EXPORT_LIST = 'EXPORT_LIST';
export const SAVE_FINAL_PAIRING = 'SAVE_FINAL_PAIRING';

//JUDGE
export const SET_JUDGE = 'SET_JUDGE';
export const GET_JUDGE = 'GET_JUDGE';
export const GET_ALL_JUDGES = 'GET_ALL_JUDGES';
export const GET_JUDGES_PWD = 'GET_JUDGES_PWD';
export const GET_JUDGE_PROJECTS = 'GET_JUDGE_PROJECTS';
export const GET_JUDGES_LIST = 'GET_JUDGES_LIST';
export const CLEAR_JUDGES_LIST = 'CLEAR_JUDGES_LIST';
export const CREATE_JUDGE = 'CREATE_JUDGE';
export const UPDATE_JUDGE = 'UPDATE_JUDGE';
export const DELETE_JUDGE = 'DELETE_JUDGE';
export const SAVE_RESULT = 'SAVE_RESULT';

//PROJECT
export const SET_PROJECT = 'SET_PROJECT';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
export const CLEAR_PROJECTS_LIST = 'CLEAR_PROJECTS_LIST';
export const CLEAR_PROJECT_INFOS = 'CLEAR_PROJECT_INFOS';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

//REGION
export const SET_REGION = 'SET_REGION';
export const GET_REGION = 'GET_REGION';
export const GET_ALL_REGIONS = 'GET_ALL_REGIONS';
export const ADD_REGION = 'ADD_REGION';
export const UPDATE_REGION = 'UPDATE_REGION';
export const DELETE_REGION = 'DELETE_REGION';

export const GET_ERRORS = 'GET_ERRORS';
export const GET_VALIDATION_ERRORS = 'GET_VALIDATION_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_MESSAGE = 'GET_MESSAGE';
export const SET_ACTION_RESPONSE = 'SET_ACTION_RESPONSE';
export const CLEAR_ACTION_RESPONSE = 'CLEAR_ACTION_RESPONSE';

export const SET_SPINNER = 'SET_SPINNER';
