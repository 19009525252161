export const classifications = {
  '1': "Juvénile 1 (1er cycle du primaire)",
  '2': "Juvénile 2 (2e cycle du primaire)",
  '3': "Juvénile 3 (3e cycle du primaire)",
  '4': "Junior (secondaire 1)",
  '5': "Intermédiaire (secondaire 2 et 3)",
  '6': "Senior (secondaire 4 et 5)",
  '7': "Collégial 1 (1re et 2e année du cégep)",
  '8': "Collégial 2 (1re à 3e année d'un programme technique du collégial ou une 3e année du double DEC)",
  '9': "Senior (secondaire  4 et 5)",
  '10': 'Collégial 2 (1re à la 3e année d’un programme technique du collégial ou une 3e année du double Dec)'

};
export const categories = {
  bio_health: 'Sciences biologiques et sciences de la santé',
  human_social: 'Sciences sociales et réseaux sociaux',
  pure_sciences: 'Sciences pures',
  environment_ecosystems: 'Environnement et écosystèmes',
  engineering_computer_robotics: 'Ingénierie, informatique et robotique',
};
export const types = {
  vulgarization: 'Vulgarisation',
  experimentation: 'Expérimentation',
  conception: 'Conception',
};
