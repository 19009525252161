import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import isEmpty from "../../validation/isEmpty";
import ProjectSwitchModal from "./ProjectSwitchModal";
import { UpdateFinal } from "../../store/actions/finalActions";

import classnames from "classnames";
import { getLogger } from "nodemailer/lib/shared";
/**
 * @props attributionInfos  Object  Contains the pairing infos for a project
 * @props minJudges         Int     The minimal number of judges per project
 * @props number            Int     The project number
 */

class AttributionRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: [],
            modal: "",
            pairing: ""
        };
    }
    componentDidMount = () => {
        //if (!this.CheckJudgeAmount()) this.props.ShowMissingJudge(this.props.number);
        window.setTimeout(() => {
            this.ManageCols();
        }, 300);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.final.selectedFinal !== this.props.final.selectedFinal) {
            console.log("UPDATE CHANGE IN FINAL INFOS");

            //this.ManageCols();
        }
    };

    HandleClick = e => {
        const { project, judge, period } = e.currentTarget.dataset;
        console.log("click", project, judge, period);

        const results = this.props.final.selectedFinal.results;

        this.ShowProjectModal(project, judge, period, results);
    };

    RemoveJudge = (project, period, judge) => {
        if (judge === undefined) return;
        const final = this.props.final && this.props.final.selectedFinal;
        const pairing = this.props.final && this.props.final.selectedFinal.pairing;
        const results = this.props.final && this.props.final.selectedFinal.results;

        //console.log("results", results);
        // Cast to int, got converted to string somewhere
        project = parseInt(project);
        period = parseInt(period);
        judge = parseInt(judge);

        //Check if pairingByProjects is not empty
        //Empty pairingByProjects for the specific period
        if (pairing.pairingByProjects[project] && !isEmpty(pairing.pairingByProjects[project][period])) {
            delete pairing.pairingByProjects[project][period].judge;
        }

        //Check if pairingByProjects is not empty
        //Empty pairingByProjects for the specific period
        if (pairing.pairingByJudges[judge] && !isEmpty(pairing.pairingByJudges[judge][period])) {
            delete pairing.pairingByJudges[judge][period].project;
        }

        //Check if results is not empty
        //Empty results for the specific period
        if (results && results[project]) {
            delete results[project][judge];
            if (isEmpty(results[project])) {
                delete results[project];
            }
        }

        //console.log("remove end", final, pairing, results);

        final.pairing = pairing;
        final.results = results;

        this.props.UpdateFinal(final);
        this.ManageCols();
    };
    ShowProjectModal = (project, judge, period, results) => {
        let list = this.props.project.projectsList;
        list = this.CheckAvailibility(list, period);

        const modal = (
            <ProjectSwitchModal
                project={project}
                period={period}
                judge={judge}
                results={results}
                list={list}
                ClearModal={this.ClearModal}
                SavePairing={this.SavePairing}
                GoToGrid={this.GoToGrid}
                RemoveJudge={this.RemoveJudge}
            />
        );
        this.setState({ modal }, () => {
            document.getElementById("modalJudge-btn").click();
        });
    };

    SavePairing = (newProject, period, judge, oldProject) => {
        console.log("Switch", newProject, period, judge, oldProject);
        if (newProject === undefined) return;

        const final = this.props.final && this.props.final.selectedFinal;
        const pairing = this.props.final && this.props.final.selectedFinal.pairing;
        const { pairingByJudges, pairingByProjects } = pairing;

        const results = this.props.final && this.props.final.selectedFinal.results;

        // Cast to int, got converted to string somewhere
        newProject = parseInt(newProject);
        oldProject = parseInt(oldProject);
        period = parseInt(period);
        judge = parseInt(judge);

        let tempOldJudge;

        // Sets Empty Pairing
        if (isEmpty(pairingByProjects[newProject])) {
            pairingByProjects[newProject] = {};
            for (let i = 1; i <= 8; i++) {
                pairingByProjects[newProject][i] = {
                    project: newProject,
                    period: i
                };
            }
        }

        //If it is a new judge added after pairing has been done
        if (isEmpty(pairingByJudges[judge])) {
            pairingByJudges[judge] = {};
            for (let i = 1; i <= 8; i++) {
                pairingByJudges[judge][i] = {
                    judge,
                    period: i
                };
            }
        }

        // If Project is judged by someone else
        if (!isEmpty(pairingByProjects[newProject][period].judge)) {
            tempOldJudge = pairingByProjects[newProject][period].judge;
        }

        console.log("OLD", tempOldJudge, oldProject);

        //Saves change new judge infos
        pairingByProjects[newProject][period] = {
            project: newProject,
            period,
            judge
        };

        pairingByJudges[judge][period] = {
            project: newProject,
            period,
            judge
        };

        if (!isEmpty(pairingByProjects[oldProject])) {
            delete pairingByProjects[oldProject][period].judge;
        }
        if (!isEmpty(results[oldProject]) && !isEmpty(results[oldProject][judge])) {
            delete results[oldProject][judge];
        }

        //console.log(results);
        //Save new info
        final.pairing.pairingByJudges = pairingByJudges;
        final.pairing.pairingByProjects = pairingByProjects;
        //final.results = results;

        this.props.UpdateFinal(final);
        this.ManageCols();
    };

    GoToGrid = (project, judge, period, results) => {
        this.props.history.push({
            pathname: `/admin/finale/${this.props.match.params[0]}/grid/${project}`,
            state: {
                period: period,
                project: project,
                judge: judge,
                finalId: this.props.match.params[0],
                isAdmin: true,
                results: this.CheckExistingResult(project, judge, results) ? this.ImportResults(project, judge, results) : {}
            }
        });
    };

    ClearModal = () => {
        document.getElementById("closeModalBtn").click();
        this.setState({ modal: "" });
    };
    CheckExistingResult = (projectNumber, judgeNumber, results) => {
        if (isEmpty(results)) return false;
        if (isEmpty(results[projectNumber])) return false;
        if (isEmpty(results[projectNumber][judgeNumber])) return false;

        if (results[projectNumber][judgeNumber].hasOwnProperty("results")) {
            return true;
        }

        return false;
    };

    ImportResults = (projectNumber, judgeNumber, results) => {
        return results[projectNumber][judgeNumber];
    };
    ManageCols = () => {
        const judgeNumber = this.props.judgeNumber;
        const cols = [];

        //FOR each period, fill the col with data
        for (let period = 1; period <= 9; period++) {
            const projectNumber = this.GetPairingInfos(judgeNumber, period);
            cols.push(this.FillCol(projectNumber, period, judgeNumber));
        }

        this.CheckJudgeAmount();

        //SAVE cols into state
        this.setState({ cols });
    };

    /**
     * Checks for existing pairing
     * @param	{number} judgeNumber
     * @param	{number} period
     * @returns {number|undefined|null} 	judge returns the project number, an empty string if no project is assigned,null if no pairing is found
     */
    GetPairingInfos = (judgeNumber, period) => {
        const pairingInfos = this.props.final.selectedFinal.pairing.pairingByJudges;
        // Nothing is assigned to the project
        if (isEmpty(pairingInfos) || isEmpty(pairingInfos[judgeNumber])) return null;
        // No judge is assigned
        if (isEmpty(pairingInfos[judgeNumber][period])) return undefined;

        return pairingInfos[judgeNumber][period].project;
    };

    FillCol = (projectNumber, period, judgeNumber) => {
        const isComplete = this.CheckJudgmentStatus(projectNumber, judgeNumber);
        return (
            <div
                key={projectNumber + "" + period}
                className={classnames(
                    "col-md grid-cell",
                    {
                        "grid-cell-complete": isComplete === true
                    },
                    {
                        "grid-cell-partial": isComplete === false
                    }
                )}
                data-cell={`${projectNumber}-${judgeNumber}`}
                data-judge={judgeNumber}
                data-project={projectNumber}
                data-period={period}
                onClick={this.HandleClick}
            >
                {isEmpty(projectNumber) ? (
                    " - "
                ) : (
                    <div>
                        Projet {projectNumber}{" "}
                        {isComplete && (
                            <span>
                                <i className="fas fa-check" />
                            </span>
                        )}
                    </div>
                )}
            </div>
        );
    };

    /**
     * Check is judgement is completed for specific project and judge
     * @param {number} projectNumber
     * @param {number} judgeNumber
     * @return {true|false} Returns true if judgement is complete.Else returns false.
     */
    CheckJudgmentStatus = (projectNumber, judgeNumber) => {
        const results = this.props.final.selectedFinal.results;
        if (results === undefined || isEmpty(results[projectNumber]) || isEmpty(results[projectNumber][judgeNumber])) return null;
        return results[projectNumber][judgeNumber].isComplete;
    };

    CheckAvailibility = (list, period) => {
        let filteredList = this.CheckProjectOtherPeriods(list);
        filteredList = this.CheckIfIsJudgingAtPeriod(filteredList, period);
        return filteredList;
    };
    /**
     * Removes judges that already paired with the project during another period
     * @param {object[]} list the from props.judge.judgesList
     * @return {object[]} the filtered list
     */
    CheckProjectOtherPeriods = list => {
        let newList = list.filter(project => {
            if (project.number === null) return false;

            // for each period check availability
            for (let period in this.props.attributionInfos) {
                if (this.props.attributionInfos[period].project === project.number) return false;
            }

            return true;
        });
        return newList;
    };

    /**
     * Removes judges that are evaluating another project during given period
     */
    CheckIfIsJudgingAtPeriod = (list, period) => {
        const pairing = this.props.final.selectedFinal.pairing.pairingByJudges;

        let newList = list.filter(project => {
            if (project.number === null) return false;

            //Remove judge from list, if exist in pairing for same period,
            for (let judge in pairing) {
                if (pairing[judge][period] && pairing[judge][period].project === project.number) return false;
            }

            return true;
        });
        return newList;
    };

    CheckJudgeAmount = () => {
        const { pairingByProjects } = this.props.final.selectedFinal.pairing;

        if (isEmpty(this.props.attributionInfos)) return;
        //console.log("Aucun pairage");

        Object.keys(pairingByProjects).map(project => {
            let judgeAmount = 0;

            Object.keys(pairingByProjects[project]).map(period => {
                if (!isEmpty(pairingByProjects[project][period].judge)) {
                    judgeAmount++;
                }
            });

            judgeAmount === this.props.minJudges ? this.props.ShowMissingJudge(project, false) : this.props.ShowMissingJudge(project, true);
            return null;
        });
    };

    render() {
        return (
            <Fragment>
                {this.state.modal}
                {this.state.cols}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    judge: state.judge,
    project: state.project,
    final: state.final
});

export default connect(mapStateToProps, { UpdateFinal })(AttributionRow);
