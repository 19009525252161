import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {
  categories,
  classifications,
  type,
  types,
} from '../../enums/classifications_categories';
import {SaveFinalPairing} from '../../store/actions/finalActions';
import isEmpty from '../../validation/isEmpty';

class UpdateProjectModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      number: '',
      classification: '',
      participants: [],
      information: {
        projectInformation: {
          title: '',
          category: '',
          type: '',
          languageEnglish: 'no',
          languageFrench: 'no',
          summary: '',
          origin: '',
        },
      },
      roomIndex:0
    };
  }

  componentDidMount () {
    const projectInfos = this.props.project;
    const newState = {
      ...this.state,
      ...projectInfos,
    };
    this.setState (newState);
  }

  UpdateInfos = () => {
    this.props.UpdateProjectInfos (this.state);
  };

  ResetPairingInfos = () => {
    const pairingFilenameInUse = null;

    const pairingInfos = {
      _id: this.props.final.selectedFinal._id,
      pairing: {
        pairingByProjects: {},
        pairingByJudges: {},
      },
      pairingFilenameInUse,
      results: {},
      finalResults: [],
      reportsResults: {},
    };
    this.props.SaveFinalPairing (pairingInfos, this.IsSaved);
  };

  IsSaved = (isSaved, pairing) => {
    return null;
  };
  HandleSelect = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleText = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleProjectInformation = e => {
    const {information} = this.state;
    if (information.projectInformation === undefined) {
      information.projectInformation = {};
    }
    information.projectInformation[e.currentTarget.name] =
      e.currentTarget.value;
    this.setState ({information});
  };
  HandleNumberChange = e => {
    this.ResetPairingInfos ();
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleCheck = e => {
    const {information} = this.state;
    if (information.projectInformation === undefined) {
      information.projectInformation = {};
    }

    information.projectInformation[e.currentTarget.name] = e.currentTarget
      .checked
      ? 'yes'
      : 'no';
    this.setState ({information});
  };

  RenderMainInfos = () => {
    const {number, classification, information} = this.state;
    const {projectInformation={}} = information;
    const {
      title,
      category,
      type,
      languageEnglish,
      languageFrench,
      summary,
      origin,
    } = projectInformation;

    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Informations</h4>
        </div>
        {!isEmpty (this.props.final.selectedFinal.pairingFilenameInUse) &&
          <div className="alert alert-info">
            <h2>
              <i className="fas fa-exclamation-triangle pr-3" />
              Pairage existant
            </h2>
            <p>
              Attention, en modifiant un numéro de projet, le logiciel effacera le pairage en cours et les notes déjà saisies s'il y a lieu. Vous devrez regénérer et réenregistrer un nouveau pairage. En cas de doute, enregistrez une archive dans la section "Informations sur la finale".
            </p>
          </div>}
        <div className="form-group col-md-6">
          <label htmlFor="">Numéro de projet</label>
          <input
            type="text"
            className="form-control"
            name="number"
            id="number"
            onChange={this.HandleNumberChange}
            value={number}
          />
        </div>
        <div className="col-md-6" />
        <div className="form-group col-md-12">
          <label htmlFor="">Titre du projet</label>
          <input
            type="text"
            className="form-control"
            name="title"
            id="title"
            onChange={this.HandleProjectInformation}
            value={title}
          />
        </div>
      </div>
    );
  };

  RenderCategoryPreferences = () => {
    const {information} = this.state;
    const {projectInformation={}} = information;
    const {category} = projectInformation;

    return (
      <div className="form-group col-md-6">
        <label htmlFor="category">Catégorie</label>
        <select
          className="custom-select"
          name="category"
          id="category"
          onChange={this.HandleProjectInformation}
          value={category}
        >
          <option value="">Choisir</option>
          {this.RenderCategoryOptions ()}
        </select>
      </div>
    );
  };

  RenderCategoryOptions = () => {
    const options = Object.keys (categories).map (cat => {
      return <option key={cat} value={cat}>{categories[cat]}</option>;
    });

    return options;
  };

  RenderAcademicPreferences = () => {
    const {classification} = this.state;

    return (
      <div className="form-group col-md-6">
        <label htmlFor="classification">Classe</label>
        <select
          className="custom-select"
          name="classification"
          id="classification"
          onChange={this.HandleText}
          value={classification}
        >
          <option value="">Choisir</option>
          {this.RenderAcademicOptions ()}
        </select>
      </div>
    );
  };

  RenderAcademicOptions = () => {
    const options = Object.keys (classifications).map (key => {
      return (
        <option key={key} value={classifications[key]}>
          {classifications[key]}
        </option>
      );
    });

    return options;
  };

  RenderTypePreferences = () => {
    const {information} = this.state;
    const {projectInformation={}} = information;
    const {type} = projectInformation;
    return (
      <div className="form-group col-md-6">
        <label htmlFor="type">Type</label>
        <select
          className="custom-select"
          name="type"
          id="type"
          onChange={this.HandleProjectInformation}
          value={type}
        >
          <option value="">Choisir</option>
          {this.RenderTypeOptions ()}
        </select>
      </div>
    );
  };

  RenderTypeOptions = () => {
    const options = Object.keys (types).map (key => {
      return <option key={key} value={key}>{types[key]}</option>;
    });

    return options;
  };

  RenderLanguagePreferences = () => {
    const {information} = this.state;
    const {projectInformation={}} = information;
    const {languageEnglish, languageFrench} = projectInformation;

    return (
      <div className="col-md-6">
        <div className="col-md-12 ">
          <h4>Choix de langue</h4>
        </div>
        <div className="row col-md-12 px-5">
          <div className="custom-control custom-checkbox col-md-6">
            <input
              type="checkbox"
              name="languageFrench"
              className="custom-control-input"
              id="languageFrench"
              checked={languageFrench === 'yes' ? 'checked' : ''}
              onChange={this.HandleCheck}
            />
            <label className="custom-control-label" htmlFor="languageFrench">
              Le projet peut être jugé en français
            </label>
          </div>
          <div className="custom-control custom-checkbox col-md-6">
            <input
              type="checkbox"
              className="custom-control-input"
              name="languageEnglish"
              id="languageEnglish"
              checked={languageEnglish === 'yes' ? 'checked' : ''}
              onChange={this.HandleCheck}
            />
            <label className="custom-control-label" htmlFor="languageEnglish">
              Le projet peut être jugé en anglais
            </label>
          </div>
        </div>
      </div>
    );
  };

  RenderRoomSelection = () => {
    const { roomIndex } = this.state;
    const { rooms } = this.props.final.selectedFinal;

    const options = rooms.map((room, index) => {
      return <option key={index} value={index} data-index={index}>{room.link}</option>
    })

    return (
      <div className="form-group col-md-6">
        <div><label htmlFor="">Salle de la présentation</label></div>
        <select value={roomIndex} onChange={this.OnHandleRoomChange}>
          {options}
        </select>
      </div>
    )
  }

OnHandleRoomChange = (event)=>{
  let roomIndex = +event.currentTarget.value;
  this.setState({roomIndex})
}
  render () {
    let isInvalid = false;

    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalUpdateJudge"
          id="modalJudge-btn"
        />
        <div
          className="modal fade"
          id="modalUpdateJudge"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalJudgeLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="modalJudgeLabel">
                  Modifier les informations d'un projet
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModalBtn"
                  onClick={this.props.ClearModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr />
              <div className="modal-body" id="modalJudge-body">
                {this.RenderMainInfos ()}
                <hr />
                <div className="row">
                  {this.RenderCategoryPreferences ()}
                  {this.RenderAcademicPreferences ()}
                  {this.RenderTypePreferences ()}
                  {this.RenderLanguagePreferences ()}
                </div>
                <div className="row"> 
                  {this.RenderRoomSelection()}
                </div>

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={classnames ('btn btn-primary', {
                    'btn-disabled': isInvalid,
                  })}
                  onClick={this.UpdateInfos}
                >
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.ClearModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  final: state.final,
});

const mapDispatchToProps = {SaveFinalPairing};

export default connect (mapStateToProps, mapDispatchToProps) (
  UpdateProjectModal
);
