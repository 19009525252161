import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link, withRouter } from 'react-router-dom';
import FinalNav from '../pages/partials/FinalNav';
import NewProjectModal from './NewProjectModal';
import UpdateProjectModal from './UpdateProjectModal';
import FinalViewProjectsInfos
  from '../finals/finalPanels/FinalViewProjectsInfos';

import { SelectFinalById } from '../../store/actions/finalActions';
import {
  SelectProjectsByFinalId,
  UpdateProject,
} from '../../store/actions/projectActions';
import { GetJudgesPwd } from '../../store/actions/judgeActions';
import isEmpty from '../../validation/isEmpty';

export class AdminProjectsList extends Component {
  constructor(props) {
    super(props);

    this.state = { modal: '' };
  }
  componentDidMount = () => {
    this.props.SelectFinalById(this.props.match.params[0]);
    this.props.SelectProjectsByFinalId(this.props.match.params[0]);
    //this.props.GetJudgesPwd (this.props.match.params[0]);
  };
  ShowNewProjectModal = () => {
    const modal = (
      <NewProjectModal
        ClearModal={this.ClearModal}
        finalId={this.props.match.params[0]}
      />
    );

    this.setState({ modal }, () => {
      document.getElementById('modalJudge-btn').click();
    });
  };

  ShowUpdateModal = project => {
    const modal = (
      <UpdateProjectModal
        ClearModal={this.ClearModal}
        UpdateProjectInfos={this.UpdateProjectInfos}
        project={project}
      />
    );

    this.setState({ modal }, () => {
      document.getElementById('modalJudge-btn').click();
    });
  };

  UpdateProjectInfos = projectInfos => {
    this.props.UpdateProject(projectInfos);
    this.ClearModal();
  };
  ClearModal = () => {
    document.getElementById('closeModalBtn').click();
    this.setState({ modal: '' });
  };

  RenderAddProjectButton = () => {
    return (
      <div>
        <div className="row py-4">
          <div className="col-md-12">
            <h4>Ajouter un projet</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <button
              className="btn btn-block btn-main pl-3"
              onClick={this.ShowNewProjectModal}
            >
              <span className="pr-2"><i className="fas fa-plus-circle" /></span>
              Ajouter un projet supplémentaire
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const id = this.props.match.params[0];
    const final = this.props.final.selectedFinal;
    const roomLink = final.rooms && final.rooms.length > 1 && <div className='px-5'>
      <NavLink
        to={`/admin/finale/${id}/projets-salles`}
        className="px-3 icon-button"
      >
        <h3><i className="fas fa-door-open" /> Sélection des salles</h3>
      </NavLink>
    </div>
    return (
      <div>
        <FinalNav
          pageTitle="Finale - Liste des projets"
          id={id}
          finalName={final.longName}
        />
        {roomLink}

        <div className="px-5 pb-5 pt-2">
          {this.state.modal}
          {/* PASSWORD LIST */}
          <div className="row pt-3">
            <div className="col-md-6">
              <h4>Liste des projets inscrits</h4>
            </div>
          </div>
          {!isEmpty(this.props.final.selectedFinal.pairingFilenameInUse) &&
            <div className="alert alert-info">
              <h2>
                <i className="fas fa-exclamation-triangle pr-3" />
                Pairage existant
              </h2>
              <p>
                Attention, en modifiant un numéro de projet, le logiciel effacera le pairage en cours et les notes déjà saisies s'il y a lieu. Vous devrez regénérer et réenregistrer un nouveau pairage. En cas de doute, enregistrez une archive dans la section "Informations sur la finale".
              </p>
            </div>}
          <FinalViewProjectsInfos ShowUpdateModal={this.ShowUpdateModal} />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  final: state.final,
});

const mapDispatchToProps = {
  SelectFinalById,
  SelectProjectsByFinalId,
  GetJudgesPwd,
  UpdateProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  AdminProjectsList
);
