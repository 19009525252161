import {
  CREATE_FINAL,
  SET_ACTION_RESPONSE,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_ALL_FINALS,
  SELECT_FINAL,
  SAVE_FINAL_PAIRING,
  DELETE_FINAL,
  SAVE_RESULT,
  CLEAR_FINAL,
  IS_FINAL_ACTIVE,
} from './types';
import axios from 'axios';
import isEmpty from '../../validation/isEmpty';

export const CreateFinal = (finalInfos, userId, isAdmin) => dispatch => {
  axios
    .post ('/api/final/create', finalInfos)
    .then (newFinal => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: CREATE_FINAL, response: 'success'},
      });

      dispatch ({type: CREATE_FINAL, payload: newFinal.data});
      dispatch (GetAllFinals (userId, isAdmin));
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: CREATE_FINAL, response: err},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const CheckFinalActive = finalId => dispatch => {
  axios
    .get ('/api/final/active', {params: {finalId}})
    .then (result => {
      dispatch ({type: IS_FINAL_ACTIVE, payload: result.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const SelectFinalById = finalId => dispatch => {
  axios
    .get ('/api/final/id', {params: {finalId}})
    .then (final => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SELECT_FINAL, response: 'success'},
      });
      dispatch ({type: SELECT_FINAL, payload: final.data});
    })
    .catch (err => {
      console.log (err);

      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SELECT_FINAL, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const GetAllActiveFinalsIds = () => dispatch => {
  axios
    .get ('/api/final/all-active-ids')
    .then (finalList => {
      dispatch ({type: GET_ALL_FINALS, payload: finalList.data});
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: GET_ALL_FINALS, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const GetAllFinals = (id, isAdmin) => dispatch => {
  if (isAdmin) {
    dispatch (GetFinalsFromAdmin ());
  } else {
    dispatch (GetFinalsFromUser (id));
  }
};

export const GetFinalsFromAdmin = () => dispatch => {
  axios
    .get ('/api/final/all')
    .then (finalList => {
      dispatch ({type: GET_ALL_FINALS, payload: finalList.data});
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: GET_ALL_FINALS, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const GetFinalsFromUser = adminId => dispatch => {
  axios
    .get ('/api/final/admin-id', {params: {adminId}})
    .then (finalList => {
      dispatch ({type: GET_ALL_FINALS, payload: finalList.data});
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: GET_ALL_FINALS, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const ToggleFinalActivation = (
  finalId,
  isAdmin = false,
  userId = null
) => dispatch => {
  axios
    .put ('/api/final/activate-final', {finalId})
    .then (result => {
      dispatch (GetAllFinals (userId, isAdmin));
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: DELETE_FINAL, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const SaveFinalPairing = (pairingInfos, cb) => dispatch => {
  axios
    .post ('/api/final/pairing', pairingInfos)
    .then (finalInfos => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SAVE_FINAL_PAIRING, response: 'success'},
      });
      cb (true, pairingInfos);
      dispatch (SelectFinalById (finalInfos.data._id));
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SAVE_FINAL_PAIRING, response: 'fail'},
      });
      cb (false, pairingInfos);
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
export const ResetProjectJudgement = (finalId, projectNumber) => dispatch => {
  axios
    .get ('/api/final/id', {params: {finalId}})
    .then (final => {
      final = final.data;
      //If no result, return
      if (isEmpty (final.results) || isEmpty (final.results[projectNumber])) {
        return;
      }
      //Delete property for each resultª
      const results = final.results;
      //console.log("results avant", results);
      delete results[projectNumber];

      //Put back new results into final
      final.results = results;
      //console.log("results après", results);

      return axios.put ('/api/final/reset-project', final);
    })
    .then (finalInfos => {
      dispatch (SelectFinalById (finalInfos.data._id));
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SAVE_FINAL_PAIRING, response: 'fail'},
      });
      //dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
export const UpdateFinal = final => dispatch => {
  axios
    .put ('/api/final/update', final)
    .then (finalInfos => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SAVE_FINAL_PAIRING, response: 'success'},
      });
      dispatch (SelectFinalById (final._id));
    })
    .catch (err => {
      console.log ('err', err);
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SAVE_FINAL_PAIRING, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const DeleteFinal = (finalId, history, userId, isAdmin) => dispatch => {
  axios
    .delete ('/api/final', {params: {finalId}})
    .then (result => {
      dispatch (GetAllFinals (userId, isAdmin));
      history.push ('/admin/panneau-controle');
    })
    .catch (err => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: DELETE_FINAL, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const SaveResult = (
  finalId,
  judgeNumber,
  projectNumber,
  period,
  results,
  total,
  totalInternational,
  isComplete,
  isAdmin,
  history
) => dispatch => {
  axios
    .post ('/api/final/save-result', {
      finalId,
      judgeNumber,
      projectNumber,
      period,
      results,
      total,
      totalInternational,
      isComplete,
    })
    .then (response => {
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SAVE_RESULT, response: 'success'},
      });
    })
    .then (response => {
      //IF isAdmin, GO TO project view panel
      if (isAdmin) {
        //console.log("save result", finalId);
        dispatch (SelectFinalById (finalId));
        history.push (`/admin/finale/${finalId}/vue-projets`);
      } else {
        //ELSE , GO TO project judge dashboard
        history.push ('/mon-jugement');
      }
    })
    .catch (err => {
      console.log (err);
      dispatch ({
        type: SET_ACTION_RESPONSE,
        payload: {type: SAVE_RESULT, response: 'fail'},
      });
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const ClearSelectedFinal = () => dispatch => {
  dispatch ({
    type: CLEAR_FINAL,
  });
};
