import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import isEmpty from "../../../validation/isEmpty";
import FinalNav from "../../pages/partials/FinalNav";

import { SelectFinalById, SaveFinalPairing } from "../../../store/actions/finalActions";
import { SelectProjectsByFinalId } from "../../../store/actions/projectActions";
import { SetSpinner } from "../../../store/actions/authActions";

import { GetJudgesPwd } from "../../../store/actions/judgeActions";
import { categories, classifications } from "../../../enums/classifications_categories";
import { getRegion } from "../../../enums/regions";
import moment from "moment";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export class PairingAlgo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            final: {
                longName: "",
                level: "UNKNOWN",
                pairing: {
                    pairingByProjects: {},
                    pairingByJudges: {}
                }
            },

            originalJudges: [],
            originalProjects: [],
            judges: [],
            projects: [],

            judgingPeriods: 0,
            totalPeriods: 0,
            errors: {},
            ready: false,
            MIN_ELEMENTARY: 3,
            MIN_HIGHSCHOOL: 3,
            DEFAULT_HIGHSCHOOL: 5,
            MAX_ATTEMPTS: 500,
            viewbyprojects: true,
            viewbyjudges: true,
            pairingSuccess: null,
            saveSuccess: null,
            showPairing: false
        };
    }

    componentDidMount() {
        this.props.SelectFinalById(this.props.match.params[0]);
        this.props.SelectProjectsByFinalId(this.props.match.params[0]);
        this.props.GetJudgesPwd(this.props.match.params[0]);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.final !== this.props.final) {
            this.SetupFinalInfos();
            const { selectedFinal } = this.props.final;
            const { pairing } = selectedFinal;
            const { pairingByJudges, pairingByProjects } = pairing;

            if (!isEmpty(pairingByJudges) && !isEmpty(pairingByProjects)) {
                const { final } = this.state;
                final.pairing = { pairingByJudges, pairingByProjects };
                this.setState({ final });
            }
        }
    }

    //=== VALIDATION ===
    CheckEmptyProjectNumber = () => {
        const { projectsList } = this.state;

        return projectsList
            .map(p => {
                if (p === undefined) return true;
                if (isEmpty(p.number)) {
                    this.SetErrors(
                        `${p.title}_NO_PROJECT_NUMBER`,
                        `Un projet ne possède pas de numéro. Le projet ${p.title} ne sera pas inclus dans le pairage`
                    );
                    return true;
                } else {
                    return false;
                }
            })
            .some(p => {
                return p;
            });
    };

    CheckEmptyJudgeNumber = () => {
        const { judgesList } = this.state;

        return judgesList
            .map(j => {
                if (j === undefined) return true;
                if (isEmpty(j.number)) {
                    this.SetErrors(
                        `${j.title}_NO_Judge_NUMBER`,
                        `Un juge ne possède pas de numéro. Le projet ${j.title} ne sera pas inclus dans le pairage`
                    );
                    return true;
                } else {
                    return false;
                }
            })
            .some(j => {
                return j;
            });
    };

    CheckCategorieValid = category => {
        return categories[category] !== undefined;
    };

    CheckClassificationValid = c => {
        return Object.keys(classifications).find(key => {
            return classifications[key] === c;
        });
    };

    CheckJudgeValid = j => {
        return (
            j !== undefined &&
            j !== false &&
            !isEmpty(j.number) &&
            !isEmpty(j.academicFirst) &&
            !isEmpty(j.academicSecond) &&
            !isEmpty(j.academicThird) &&
            this.CheckClassificationValid(j.academicFirst) &&
            this.CheckClassificationValid(j.academicSecond) &&
            this.CheckClassificationValid(j.academicThird) &&
            !isEmpty(j.categoryFirst) &&
            !isEmpty(j.categorySecond) &&
            !isEmpty(j.categoryThird) &&
            this.CheckCategorieValid(j.categoryFirst) &&
            this.CheckCategorieValid(j.categorySecond) &&
            this.CheckCategorieValid(j.categoryThird) &&
            !isEmpty(j.typeFirst) &&
            !isEmpty(j.typeSecond) &&
            !isEmpty(j.judgingFrench) &&
            !isEmpty(j.judgingEnglish)
        );
    };

    CheckDuplicateProjectNumber = () => {
        const { projectsList } = this.state;
        let count = {};
        return projectsList
            .map(p => {
                if (p.number !== "") {
                    if (isEmpty(count[p.number])) {
                        count[p.number] = 0;
                    }

                    count[p.number]++;
                    //console.log ('Count project', count);

                    if (count[p.number] > 1) {
                        this.SetErrors(
                            "DUPLICATE_PROJECT_NUMBER",
                            `Plusieurs projets ont le numéro ${p.number}.Veuillez corriger le numéro pour continuer.`
                        );
                        return true;
                    } else {
                        return false;
                    }
                }
            })
            .some(p => {
                return p;
            });
    };

    CheckDuplicateJudgeNumber = () => {
        const { judgesList } = this.state;
        let count = {};
        return judgesList
            .map(j => {
                if (j.number !== "") {
                    if (isEmpty(count[j.number])) {
                        count[j.number] = 0;
                    }

                    count[j.number]++;
                    //console.log ('Count judge', count);

                    if (count[j.number] > 1) {
                        this.SetErrors(
                            "DUPLICATE_JUDGE_NUMBER",
                            `Plusieurs juges ont le numéro ${j.number}. Veuillez corriger le numéro pour continuer`
                        );
                        return true;
                    } else {
                        return false;
                    }
                }
            })
            .some(j => {
                return j;
            });
    };

    CheckMinJudgesAmount = () => {
        const { judgesList, projectsList, judgingPeriods, totalPeriods } = this.state;
        /*const periodToBook = projectsList.length * judgingPeriods;
    const availablePeriods = judgesList.length * totalPeriods;*/
        console.log(judgesList.length, projectsList.length)
        if (judgesList.length < judgingPeriods) {
            this.SetErrors("JUDGES_AMOUNT", "Il n'y a pas assez de juges pour le nombre de périodes et de projets");
            return false;
        }

        if (judgesList.length < (projectsList.length * judgingPeriods) / totalPeriods) {
            this.SetErrors("JUDGES_AMOUNT", "Il n'y a pas assez de juges pour le nombre de périodes et de projets");
            return false;
        }

        this.SetErrors("JUDGES_AMOUNT", null);
        return true;
    };

    CheckProjectRequiredPeriod = (projectNumber, pairingByProjects, minPeriod) => {
        const projectToCheck = pairingByProjects[projectNumber];
        const judgedPeriod = 0;

        Object.keys(projectToCheck).map(period => {
            if (projectToCheck[period].judge == undefined) {
                return false;
            } else {
                judgedPeriod++;
                return true;
            }
        });
        return judgedPeriod === minPeriod;
    };

    CheckIfProjectJudgeAlreadyMatched = (projectNumber, judgeNumber, pairing) => {
        const { totalPeriods } = this.state;
        const { pairingByProjects } = pairing;
        let found = false;

        for (let i = 1; i <= totalPeriods; i++) {
            if (
                pairingByProjects[projectNumber][i].judge !== undefined &&
                parseInt(pairingByProjects[projectNumber][i].judge) === parseInt(judgeNumber)
            ) {
                found = true;
            }
        }
        //console.log ('Found', found);

        return found;
    };

    CheckIfJugeIsAvailable = (period, judgeNumber, pairing) => {
        const { pairingByJudges } = pairing;
        if (pairingByJudges[judgeNumber][period].project !== undefined) {
            return false;
        } else {
            return true;
        }
    };

    CheckIfMaxJudgeObtained = (projectNumber, pairing) => {
        const { totalPeriods, judgingPeriods } = this.state;
        const { pairingByProjects } = pairing;
        let found = false;

        let counter = 0;
        for (let i = 1; i <= totalPeriods; i++) {
            if (pairingByProjects[projectNumber][i].judge !== undefined) {
                counter++;
            }
        }

        console.log("CheckIfMaxJudgeObtained", totalPeriods, judgingPeriods, counter);
        if (counter === parseInt(judgingPeriods)) {
            return true;
        } else {
            return false;
        }
    };

    CheckValidMatch = pairing => {
        const isInvalid = Object.keys(pairing.pairingByProjects).some(p => {
            return this.CheckIfMaxJudgeObtained(p, pairing) === false;
        });
        if (isInvalid) {
            return false;
        } else {
            return true;
        }
    };

    // === SETUP SEQUENCE ===
    Setup = async cb => {
        this.ResetPairingInfos();
        const isFinalReady = await this.SetupFinalInfos();
        const isProjectReady = await this.SetupProjectsList();
        const isJudgesReady = await this.SetupJudgesList();

        //this.ResetPairing ();
        this.setState({ ready: isFinalReady && isProjectReady && isJudgesReady }, () => {
            this.SetupComplete();
            this.CheckMinJudgesAmount();
        });
    };

    SetupFinalInfos = async () => {
        const isSetupOk = new Promise((resolve, reject) => {
            const { selectedFinal } = this.props.final;

            if (isEmpty(selectedFinal)) {
                this.SetErrors("EMPTY_EVENT", "Aucune finale d'enregistrée");
                reject(false);
            }

            const { level } = selectedFinal;
            const judgingPeriods = level === "highschool" ? this.state.DEFAULT_HIGHSCHOOL : this.state.MIN_ELEMENTARY;
            const totalPeriods = level === "highschool" ? +this.state.DEFAULT_HIGHSCHOOL + 1 : +this.state.MIN_ELEMENTARY + 1;

            this.setState({ judgingPeriods, totalPeriods }, () => {
                resolve(true);
            });
        })
            .then(res => {
                return res;
            })
            .catch(err => {
                return err;
            });

        return await isSetupOk;
    };

    SetupProjectsList = async () => {
        const { projectsList } = this.props.project;
        //ERROR IF NO LIST
        const isSetupOk = new Promise((resolve, reject) => {
            if (isEmpty(projectsList)) {
                this.SetErrors("EMPTY_PROJECT", "Aucun projet d'enregistré pour cette finale");
                reject(false);
            }

            const cleanlist = projectsList
                .map(p => {
                    const { classification, number, information } = p;

                    if (isEmpty(p) || isEmpty(information) || isEmpty(information.projectInformation) || isEmpty(classification)) {
                        this.SetErrors(
                            `Project_MISSING_INFORMATION ${number || ""}`,
                            `Un projet ne possède pas d'information. Le projet ${(information && information.title) ||
                            ""} ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }

                    const { category, languageEnglish, languageFrench, type, title } = information.projectInformation;

                    if (isEmpty(category) || (isEmpty(languageEnglish) && isEmpty(languageFrench)) || isEmpty(type) || isEmpty(title)) {
                        /*  console.log (
              category,
              languageEnglish,
              languageFrench,
              type,
              title
            ); */
                        this.SetErrors(
                            `Project_MISSING_INFORMATION ${number || ""}`,
                            `Un projet ne possède pas d'information. Le projet ${(information && information.title) ||
                            ""} ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }
                    //Check if no number
                    if (isEmpty(number)) {
                        this.SetErrors(
                            `${title}_NO_PROJECT_NUMBER`,
                            `Un projet ne possède pas de numéro. Le projet ${title} ne sera pas inclus dans le pairage`
                        );
                    }

                    const project = {
                        classification,
                        number,
                        category,
                        languageEnglish,
                        languageFrench,
                        type,
                        title
                    };

                    return project;
                })
                .filter(p => {
                    return p !== false && !isEmpty(p.number);
                });
            //console.log ('Project list', cleanlist);

            this.setState({ projectsList: cleanlist }, () => {
                //CHECKS IF NO DUPLICATE
                if (!this.CheckDuplicateProjectNumber()) {
                    this.SetupPairingByProjects();
                }
                resolve(true);
            });
        })
            .then(res => {
                return res;
            })
            .catch(err => {
                return err;
            });

        return await isSetupOk;
    };

    SetupJudgesList = async () => {
        const { judgesList } = this.props.judge;

        const isSetupOk = new Promise((resolve, reject) => {
            if (isEmpty(judgesList)) {
                this.SetErrors("EMPTY_JUDGE", "Aucun juge d'enregistré pour cette finale");
                reject(false);
            }

            const cleanlist = judgesList
                .map(j => {
                    const { number, information } = j;
                    const { judgingPreference, judgingExperience, generalInformation } = information;

                    if (
                        isEmpty(j) ||
                        isEmpty(information) ||
                        isEmpty(judgingPreference) ||
                        isEmpty(judgingExperience) ||
                        isEmpty(generalInformation)
                    ) {
                        this.SetErrors(
                            `JUDGE_MISSING_INFORMATION ${number || ""}`,
                            `Un juge ne possède pas d'information. Le juge ${generalInformation.lastName || ""} ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }

                    if (
                        j.status !== "Approuvé"
                    ) {
                        this.SetErrors(
                            `JUDGE_MISSING_INFORMATION ${number || ""}`,
                            `Le juge no.${number || ""}, ${generalInformation.firstName || ""} ${generalInformation.lastName || ""} n'est pas approuvé et ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }
                    const {
                        academicFirst,
                        academicSecond,
                        academicThird,
                        categoryFirst,
                        categorySecond,
                        categoryThird,
                        typeFirst,
                        typeSecond
                    } = judgingPreference;
                    const { judgingFrench, judgingEnglish } = judgingExperience;
                    const { firstName, lastName } = generalInformation;

                    //Check if no number
                    if (isEmpty(number)) {
                        this.SetErrors(
                            `${information.generalInformation.lastName}_NO_JUDGE_NUMBER`,
                            `Un juge ne possède pas de numéro. Le juge ${information.generalInformation.firstName} ${information.generalInformation.lastName} ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }

                    //Check if no academic choice
                    if (isEmpty(academicFirst) || isEmpty(academicSecond) || isEmpty(academicThird)) {
                        this.SetErrors(
                            `${information.generalInformation.lastName}_ACADEMIC_CHOICE_MISSING`,
                            `Un juge n'a pas fait de choix sur le niveau scolaire souhaité. Le juge ${information.generalInformation.firstName} ${information.generalInformation.lastName} ne sera pas inclus dans le pairage`
                        );
                    }

                    //Check if no category choice
                    if (isEmpty(categoryFirst) || isEmpty(categorySecond) || isEmpty(categoryThird)) {
                        this.SetErrors(
                            `${information.generalInformation.lastName}_CATEGORY_MISSING`,
                            `Un juge n'a pas fait de choix sur la catégorie souhaitée. Le juge ${information.generalInformation.firstName} ${information.generalInformation.lastName} ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }

                    //Check if no type choice
                    if (isEmpty(typeFirst) || isEmpty(typeSecond)) {
                        this.SetErrors(
                            `${information.generalInformation.lastName}_TYPE_MISSING`,
                            `Un juge n'a pas fait de choix sur le type de projet à juger. Le juge ${information.generalInformation.firstName} ${information.generalInformation.lastName} ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }

                    //Check if no type choice
                    if (isEmpty(judgingFrench) && isEmpty(judgingEnglish)) {
                        this.SetErrors(
                            `${information.generalInformation.lastName}_LANGUAGE_MISSING`,
                            `Un juge n'a pas fait de choix sur la langue du projet à juger. Le juge ${information.generalInformation.firstName} ${information.generalInformation.lastName} ne sera pas inclus dans le pairage`
                        );
                        return false;
                    }

                    const judge = {
                        firstName,
                        lastName,
                        number,
                        academicFirst,
                        academicSecond,
                        academicThird,
                        categoryFirst,
                        categorySecond,
                        categoryThird,
                        typeFirst,
                        typeSecond,
                        judgingFrench,
                        judgingEnglish
                    };

                    return judge;
                })
                .filter(j => {
                    return this.CheckJudgeValid(j);
                });
            this.setState({ judgesList: cleanlist }, () => {
                console.log(cleanlist);
                if (!this.CheckDuplicateJudgeNumber()) {
                    this.SetupPairingByJudges();
                }
                resolve(true);
            });
        })
            .then(res => {
                return res;
            })
            .catch(err => {
                return err;
            });

        return await isSetupOk;
    };

    SetupComplete = async () => {
        //console.log ('SETUP COMPLETE');
    };

    SetupPairingByProjects = () => {
        const { projectsList, final } = this.state;
        const pairingByProjects = {};

        projectsList.map(p => {
            const emptyObject = {};
            for (let i = 1; i <= 9; i++) {
                emptyObject[i] = { project: parseInt(p.number), period: i };
            }
            pairingByProjects[p.number] = emptyObject;
        });
        final.pairing.pairingByProjects = pairingByProjects;
        this.setState({ final });

        return pairingByProjects;
    };

    SetupPairingByJudges = () => {
        const { judgesList, final } = this.state;
        const pairingByJudges = {};

        judgesList.map(j => {
            const emptyObject = {};

            for (let i = 1; i <= 9; i++) {
                emptyObject[i] = { judge: parseInt(j.number), period: i };
            }

            pairingByJudges[j.number] = emptyObject;
            return true;
        });
        final.pairing.pairingByJudges = pairingByJudges;
        this.setState({ final });
        return pairingByJudges;
    };

    ResetPairing = () => {
        const newPairing = {
            pairingByJudges: this.SetupPairingByJudges(),
            pairingByProjects: this.SetupPairingByProjects()
        };
        this.setState({ pairing: newPairing });
        return newPairing;
    };

    // === END SETUP SEQUENCE ===

    // === MATCHING
    MatchJudgeProject = e => {
        this.props.SetSpinner(true);
        this.setState({ saveSuccess: null });
        const pairing = this.ResetPairing();

        const { projectsList } = this.state;

        const sortedList = {};

        projectsList.map(p => {
            return (sortedList[p.number] = this.SortJudges(p));
        });

        const newPairing = this.AttemptMatching(0, sortedList, pairing);
        this.props.SetSpinner(false);

        if (newPairing === null) {
            this.SetErrors(`NO_MATCH_POSSIBLE`, `Impossible de générer un match pour la finale`);
            return;
        }
        this.setState({ pairing: newPairing });
    };

    SortJudges = project => {
        const { judgesList } = this.state;

        const { classification, number, category, languageEnglish, languageFrench, type, title } = project;

        const sortedJudges = judgesList
            .map(j => {
                const judge = { j, points: 0 };

                //Sort points
                judge.points += this.GetLanguagePoints(
                    j.judgingFrench === "yes",
                    j.judgingEnglish === "yes",
                    languageFrench === "yes",
                    languageEnglish === "yes"
                );

                //Sort types
                judge.points += this.GetTypePoints(type, j.typeFirst, j.typeSecond);
                judge.points += this.GetClassificationPoints(classification, j.academicFirst, j.academicSecond, j.academicThird);

                judge.points += this.GetCategoryPoints(category, j.categoryFirst, j.categorySecond, j.categoryThird);

                return judge;
            })
            .sort((a, b) => {
                if (a.points > b.points) {
                    return -1;
                } else if (a.points < b.points) {
                    return 1;
                } else {
                    return 0;
                }
            });
        const sortedProject = { number: project.number, project, sortedJudges };
        return sortedProject;
    };

    GetLanguagePoints = (judgeFrench, judgeEnglish, projectFrench, projectEnglish) => {
        // Check english only project first
        //Check french only project
        //Check both
        if (projectEnglish === "yes" && projectFrench === "no") {
            //If can judge in english only
            if (judgeEnglish === "yes" && judgeFrench === "no") {
                return 1000;
            } else if (judgeEnglish === "yes" && judgeFrench === "yes") {
                return 1000;
            }
        } else if (projectEnglish === "no" && projectFrench === "yes") {
            //If can judge in english only
            if (judgeEnglish === "no" && judgeFrench === "yes") {
                return 1000;
            } else if (judgeEnglish === "yes" && judgeFrench === "yes") {
                return 1000;
            }
        } else {
            return 200;
        }
    };

    GetTypePoints = (projectType, judgeFirstChoice, judgeSecondChoice) => {
        if (projectType === judgeFirstChoice) {
            return 90;
        } else if (projectType === judgeSecondChoice) {
            return 60;
        } else {
            return 30;
        }
    };

    GetClassificationPoints = (classification, academicFirst, academicSecond, academicThird) => {
        if (classification === academicFirst) {
            return 90;
        } else if (classification === academicSecond) {
            return 60;
        } else if (classification === academicThird) {
            return 30;
        } else {
            return 20;
        }
    };

    GetCategoryPoints = (category, categoryFirst, categorySecond, categoryThird) => {
        if (category === categoryFirst) {
            return 90;
        } else if (category === categorySecond) {
            return 60;
        } else if (category === categoryThird) {
            return 30;
        } else {
            return 20;
        }
    };

    AssignProjectPauses = () => {
        const { judgingPeriods, totalPeriods } = this.state;
        const pausesAmount = totalPeriods - judgingPeriods;

        const pauses = {};

        for (let p = 0; p < pausesAmount; p++) {
            const randomList = this.QueueRandomlyAllProjects();

            for (let i = 0; i < randomList.length; i++) {
                let periodInPause = (i % totalPeriods) + 1;
                if (isEmpty(pauses[periodInPause])) {
                    pauses[periodInPause] = [];
                }
                pauses[periodInPause].push(parseInt(randomList[i]));
            }
        }
        //console.log ('PAUSES', pauses);

        return pauses;
    };

    AssignJudgesPauses = () => {
        const { judgingPeriods, totalPeriods, judgesList, projectsList } = this.state;
        const totalPauses = totalPeriods * judgesList.length - judgingPeriods * projectsList.length;

        const maxPauses = Math.floor(totalPauses / totalPeriods);
        const pauses = {};

        for (let p = 0; p < maxPauses; p++) {
            const randomList = this.QueueRandomlyAllJudges();

            for (let i = 0; i < Math.floor(totalPauses / maxPauses); i++) {
                let periodInPause = (i % totalPeriods) + 1;

                if (isEmpty(pauses[periodInPause])) {
                    pauses[periodInPause] = [];
                }
                //console.log (randomList[i]);
                if (randomList[i] !== undefined) {
                    pauses[periodInPause].push(parseInt(randomList[i]));
                }
            }
        }
        //console.log ('Judges PAUSES', maxPauses, pauses);

        return pauses;
    };

    AttemptMatching = (attempt, sortedList, pairing) => {
        //console.log ('ATTEMPT', attempt);
        const newPairing = this.ResetPairing();
        if (attempt > this.state.MAX_ATTEMPTS) {
            this.SetErrors(
                "MAX_ATTEMPTS",
                `Impossible de générer un pairage après ${this.state.MAX_ATTEMPTS} essais de combinaisons. Réappuyez sur Générer un pairage`
            );
            this.setState({ pairingSuccess: false });
            return null;
        } else {
            this.SetErrors("MAX_ATTEMPTS", null);
        }

        const { totalPeriods } = this.state;

        const assignedProjectPauses = this.AssignProjectPauses();
        const assignedJudgesPauses = this.AssignJudgesPauses();

        // FOR EACH PERIOD
        for (let i = 1; i <= totalPeriods; i++) {
            const availableProjects = this.QueueRandomlyAllProjects();
            let projectsInPause = assignedProjectPauses[i] || [];
            let judgesInPause = assignedJudgesPauses[i] || [];

            // While there are projects to assign
            // If project is in pause skip
            // If project has already enough judges skip
            // Else assign an available judge
            // Update pairing
            while (availableProjects.length > 0) {
                const project = availableProjects.pop();
                //console.log ('match', i, project);

                if (projectsInPause.indexOf(project) !== -1) {
                    continue;
                }

                if (this.CheckIfMaxJudgeObtained(project, newPairing)) {
                    continue;
                }

                //console.log ('judges in pause', judgesInPause, assignedJudgesPauses);

                const judge = this.AssignJudge(i, project, sortedList[project].sortedJudges, 0, newPairing, judgesInPause);

                if (judge !== null && judge !== undefined) {
                    //Add to pairing
                    newPairing.pairingByProjects[project][i].judge = parseInt(judge.number);
                    newPairing.pairingByJudges[judge.number][i].project = parseInt(project);
                } else {
                    //NO MORE JUDGE, REMOVE UPCOMING PAUSE IF ANY
                    for (let j = i; j <= totalPeriods; j++) {
                        if (assignedProjectPauses[j] === undefined) continue;

                        let upcomingPauseIndex = assignedProjectPauses[j].indexOf(project);
                        if (upcomingPauseIndex !== -1) {
                            assignedProjectPauses[j].splice(upcomingPauseIndex, 1);
                        }
                    }
                }
            }
        }
        //console.log ('IS VALID', this.CheckValidMatch (newPairing), pairing);

        if (this.CheckValidMatch(newPairing)) {
            this.setState({ pairingSuccess: true });
            return newPairing;
        } else {
            const newAttempt = attempt + 1;

            return this.AttemptMatching(newAttempt, sortedList, pairing);
        }
    };

    QueueRandomlyAllProjects = () => {
        const { projectsList } = this.state;
        const orderedList = projectsList.map(p => {
            return p.number;
        });

        const randomList = [];
        while (orderedList.length > 0) {
            const randomIndex = Math.floor(Math.random() * orderedList.length);

            randomList.unshift(parseInt(orderedList[randomIndex]));
            orderedList.splice(randomIndex, 1);
        }

        return randomList;
    };

    QueueRandomlyAllJudges = () => {
        const { judgesList } = this.state;
        const orderedList = judgesList.map(j => {
            return j.number;
        });

        const randomList = [];
        while (orderedList.length > 0) {
            const randomIndex = Math.floor(Math.random() * orderedList.length);

            randomList.unshift(parseInt(orderedList[randomIndex]));
            orderedList.splice(randomIndex, 1);
        }

        return randomList;
    };

    AssignJudge(period, project, sortedJudges, count, pairing, judgesInPause) {
        if (count >= sortedJudges.length) {
            return null;
        }

        const judge = sortedJudges[count].j;

        const isProjectTaken = !isEmpty(pairing.pairingByProjects[project].judge);
        const isMatched = this.CheckIfProjectJudgeAlreadyMatched(project, judge.number, pairing);
        const isAvailable = this.CheckIfJugeIsAvailable(period, judge.number, pairing);

        const isInPause = judgesInPause.indexOf(parseInt(judge.number)) !== -1;

        if (!isMatched && !isProjectTaken && isAvailable && !isInPause) {
            return judge;
        } else {
            //if null, no judge available for project number at period number
            // Make recursive call
            count += 1;
            return this.AssignJudge(period, project, sortedJudges, count, pairing, judgesInPause);
        }
    }

    SavePairing = () => {
        const { selectedFinal } = this.props.final;
        const { eventDate, region, level } = selectedFinal;
        const currentDate = moment().format("YY-MM-DD_HH[h]mm-ss");
        const regionName = getRegion(region);
        const levelName = level === "highschool" ? "Sec" : level === "elementary" ? "Prim" : "";
        //console.log (currentDate);

        const { pairing } = this.state.final;

        const pairingFilenameInUse = `Pairage${moment(eventDate).format("YYYY")}_${regionName.accronym}_${levelName}_${currentDate}`;

        const pairingInfos = {
            _id: this.props.match.params[0],
            pairing,
            pairingFilenameInUse,
            results: {},
            finalResults: [],
            reportsResults: {},
            judgingPeriods: this.state.judgingPeriods,
            totalPeriods: this.state.totalPeriods
        };
        this.props.SaveFinalPairing(pairingInfos, this.IsSaved);
    };

    IsSaved = (isSaved, pairing) => {
        if (this.state.pairingSuccess) {
            this.ExportJsonFile(pairing);
            this.setState({ saveSuccess: isSaved });
        }
    };
    //== END MATCHING ===

    //== IMPORTATION

    ImportJsonFile = jsonFile => {
        try {
            const json = JSON.parse(jsonFile);

            //console.log (json);
            if (isEmpty(json) || isEmpty(json.pairing)) return;

            this.props.SaveFinalPairing(json, this.IsSaved);
        } catch (error) {
            if (error instanceof SyntaxError) {
                console.log(error);
            } else {
                console.log(error);
            }
        }
    };
    //== END IMPORTATION

    //== EXPORTATION

    ExportJsonFile = pairingInfos => {
        const zip = new JSZip();

        const { pairingFilenameInUse, pairing } = pairingInfos;
        const { pairingByJudges, pairingByProjects } = pairing;
        const pairingFileName = !isEmpty(pairingFilenameInUse) ? pairingFilenameInUse : "Pairage";

        if (isEmpty(pairing)) return;
        if (isEmpty(pairingByJudges) || isEmpty(pairingByProjects)) return;

        //CREATE FOLDER FOR ZIP
        const version = zip.folder("version");
        const excel = zip.folder("excel");
        const projectsCSV = this.ConvertToCSV(pairingByProjects, "Projet", "judge");
        const judgesCSV = this.ConvertToCSV(pairingByJudges, "Juge", "project");

        //ADD FILES TO ZIP
        pairingInfos.pairingFilenameInUse = pairingFileName;
        version.file(`${pairingFileName}.json`, JSON.stringify(pairingInfos));

        excel.file(`${pairingFileName}-projets.csv`, projectsCSV);
        excel.file(`${pairingFileName}-juges.csv`, judgesCSV);

        //ZIP FILE
        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, `${pairingFileName}.zip`);
        });
    };

    ConvertToCSV = (pairing, obj = "project", objToFind = "judge") => {
        let csv = "";
        const header = `${obj};Periode A;Periode B;Periode C;Periode D;Periode E;Periode F;Periode G;Periode H;Periode I;\n`;

        csv += header;

        Object.keys(pairing).map(key => {
            let row = "";
            row += `${key};`;
            //Checks each period
            Object.keys(pairing[key]).map(period => {
                if (pairing[key][period][objToFind] !== undefined) {
                    row += `${pairing[key][period][objToFind]};`;
                } else {
                    row += ";";
                }
            });
            //End of row
            row += "\n";
            csv += row;
        });
        return csv;
    };

    HandleExportation = () => {
        const { selectedFinal } = this.props.final;
        const { pairing, pairingFilenameInUse } = selectedFinal;

        const pairingInfos = {
            _id: this.props.match.params[0],
            pairing,
            pairingFilenameInUse,
            results: {},
            finalResults: [],
            reportsResults: {}
        };
        this.ExportJsonFile(pairingInfos);
    };

    //== END EXPORTATION
    // === VALIDATION
    SetErrors = (key, message) => {
        const { errors } = this.state;
        if (isEmpty(message)) {
            delete errors[key];
        } else {
            errors[key] = message;
        }
        this.setState({ errors });
    };

    // === RENDER SECTION
    RenderErrors = () => {
        const { errors } = this.state;
        if (isEmpty(errors)) return;

        return Object.keys(errors).map(errorKey => {
            return <p>{errors[errorKey]}</p>;
        });
    };

    RenderSetupReady = () => {
        const { final, judges, projects, level, viewby } = this.state;

        return (
            !isEmpty(final.pairing) &&
            !isEmpty(final.pairing.pairingByJudges) &&
            !isEmpty(final.pairing.pairingByProjects) &&
            !isEmpty(judges) &&
            !isEmpty(projects) &&
            level !== "UNKNOWN"
        );
    };

    RenderDataValidation = () => {
        const { ready, errors } = this.state;

        return (
            <div className="py-1">
                <h5>Étape 1 - Vérifier les données des juges et des projets</h5>
                <p className="alert alert-info">
                    Les juges et les projets sans numéro et dont les préférences de jugement sont manquantes ne seront pas inclus dans le pairage.
                </p>
                <small>
                    Vérifier la section <strong>Erreurs</strong> pour savoir quels juges/projets ont été retirés du pairage
                </small>
                <div className="py-3">
                    <button className="btn btn-main" onClick={this.Setup}>
                        Valider les informations
                    </button>
                    {ready ? (
                        <p className="text-success">
                            <i className="fas fa-check pr-3" />
                            Les informations sont validées
                        </p>
                    ) : errors.length < 0 ? (
                        <p className="text-warning">
                            <i className="fas fa-times pr-3" />
                            Veuillez vérifier la section <strong>Erreurs</strong>
                        </p>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    };

    RenderPeriodChoice = () => {
        const { errors } = this.state;
        return (
            <div className="py-1">
                <h5>Étape 2 - Choisir le nombre de périodes de jugement totales</h5>
                <small>Vous ne pouvez pas aller au-delà de 9 périodes</small>
                {this.RenderControlSection()}
                {errors["JUDGES_AMOUNT"] ? (
                    <div className="alert alert-danger">
                        <p>{errors["JUDGES_AMOUNT"]}</p>
                    </div>
                ) : (
                    <p className="text-success">
                        <i className="fas fa-check pr-3" />
                        Pairage possible avec le nombre de périodes sélectionnées
                    </p>
                )}

                <h5>Modifier le nombre de jugements</h5>
                <small className="text-danger">Vous devez demander l'approbation de la Coordination des Expo-sciences avant de modifier le nombre de jugements</small>
                {this.RenderJugementAmountSection()}
                {errors["JUDGES_AMOUNT"] ? (
                    <div className="alert alert-danger">
                        <p>{errors["JUDGES_AMOUNT"]}</p>
                    </div>
                ) : (
                    <p className="text-success">
                        <i className="fas fa-check pr-3" />
                        Pairage possible avec le nombre jugements sélectionnés
                    </p>
                )}
            </div>

        );
    };

    RenderPairing = () => {
        const { viewbyjudges, viewbyprojects, ready, final, errors, pairingSuccess, saveSuccess } = this.state;
        const { pairing } = final;
        const { pairingByJudges, pairingByProjects } = pairing;

        const isValid = ready && isEmpty(errors["JUDGES_AMOUNT"]);
        return (
            <div>
                {ready && (
                    <div className="row py-1">
                        <div className="col-md-12">
                            <h5>Étape 3 - Démarrer le pairage | </h5>
                            <small>
                                Le logiciel va tenter jusqu'à 500 combinaisons pour éviter ralentir le navigateur. Si après plusieurs essais vous
                                n'arrivez pas à un pairage, augmenter le nombre de périodes
                            </small>
                            {ready && isEmpty(errors["JUDGES_AMOUNT"]) ? (
                                <p className="text-success">Pairage possible. Appuyez sur Générer un pairage</p>
                            ) : (
                                <p className="text-danger">
                                    Impossible de faire le pairage. Modifier le nombre de périodes et vérifier la section Erreurs
                                </p>
                            )}
                            {!pairingSuccess && errors["MAX_ATTEMPTS"] ? (
                                <p className="text-danger">{errors["MAX_ATTEMPTS"]}</p>
                            ) : pairingSuccess ? (
                                <p className="text-success">
                                    <i className="fas fa-check pr-3" />
                                    Pairage réussi
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-md-12 py-3">
                            <button
                                className={classnames("btn btn-main mx-1", {
                                    "btn-disabled": !isValid
                                })}
                                onClick={isValid ? this.MatchJudgeProject : undefined}
                            >
                                Générer un pairage
                            </button>
                        </div>
                        {!isEmpty(pairingByJudges) && !isEmpty(pairingByProjects) && (
                            <div className="col-md-12">
                                {saveSuccess === true ? (
                                    <p className="text-success">
                                        <i className="fas fa-check pr-3" />
                                        Sauvegarde réussie
                                    </p>
                                ) : saveSuccess === false ? (
                                    <p className="text-danger">
                                        <i className="fas fa-times pr-3" />
                                        Erreur de la sauvegarde
                                    </p>
                                ) : (
                                    ""
                                )}
                                <button
                                    className={classnames("btn btn-main mx-1", {
                                        "btn-disabled": !pairingSuccess
                                    })}
                                    onClick={pairingSuccess === true ? this.SavePairing : undefined}
                                >
                                    Sauvegarder le pairage actuel
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <div className="col-md-12">
                    <hr />
                </div>
                <div className="px-3 pt-3 form-inline row">
                    <div className="col-md-12">
                        <h4>Affichage du pairage</h4>
                    </div>
                    <div className="form-group">
                        <div className="form-check mr-4">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="viewbyprojects"
                                id="viewbyprojects"
                                onChange={this.HandleViewChange}
                                checked={viewbyprojects === true ? "checked" : ""}
                            />
                            <label className="form-check-label" htmlFor="viewbyprojects">
                                Par projets
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="viewbyjudges"
                                id="viewbyjudges"
                                onChange={this.HandleViewChange}
                                checked={viewbyjudges === true ? "checked" : ""}
                            />
                            <label className="form-check-label" htmlFor="viewbyjudges">
                                Par juges
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    {viewbyprojects && (
                        <div className="my-1">
                            <h4>Pairage par projets</h4>
                            {this.RenderPairingByProjects()}
                        </div>
                    )}
                    {viewbyjudges && (
                        <div className="my-1">
                            <h4>Pairage par juges</h4>
                            {this.RenderPairingByJudges()}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    RenderHeaderRow = () => {
        const { totalPeriods } = this.state;
        const cells = [];
        for (let i = 0; i <= totalPeriods; i++) {
            if (i === 0) {
                cells.push(<div className="col grid-cell-header" />);
            } else {
                cells.push(
                    <div className="col grid-cell-header">
                        <strong>{String.fromCharCode(i + 96).toUpperCase()}</strong>
                    </div>
                );
            }
        }
        return <div className="period-row row">{cells}</div>;
    };

    RenderPairingByProjects = () => {
        const { final, totalPeriods, judgingPeriods } = this.state;
        const { pairing } = final;

        if (isEmpty(final.pairing)) return;
        const { pairingByProjects } = pairing;

        const table = [];
        table.push(this.RenderHeaderRow());

        Object.keys(pairingByProjects).map(project => {
            let cells = [];
            let isProjectValid = this.CheckIfMaxJudgeObtained(project, pairing) || Object.keys(pairingByProjects).length === 0;

            for (let i = 0; i <= totalPeriods; i++) {
                if (i === 0) {
                    cells.push(
                        <div className="col grid-cell-header">
                            <strong>Projet {project}</strong>
                        </div>
                    );
                } else {
                    cells.push(
                        <div className="col grid-cell-header">
                            {pairingByProjects[project][i].judge ? `Juge ${pairingByProjects[project][i].judge}` : "-"}
                        </div>
                    );
                }
            }

            table.push(<div className={classnames("period-row row projectsListItem", [{ "missing-judge": !isProjectValid }])}>{cells}</div>);
        });

        return <div className="mt-1">{table}</div>;
    };

    RenderPairingByJudges = () => {
        const { final, totalPeriods, judgingPeriods } = this.state;
        const { pairing } = final;

        if (isEmpty(final.pairing)) return;
        const { pairingByJudges } = pairing;

        const table = [];
        table.push(this.RenderHeaderRow());

        Object.keys(pairingByJudges).map(judge => {
            let cells = [];
            for (let i = 0; i <= totalPeriods; i++) {
                if (i === 0) {
                    cells.push(
                        <div className="col grid-cell-header">
                            <strong>Juge {judge}</strong>
                        </div>
                    );
                } else {
                    cells.push(
                        <div className="col grid-cell-header">
                            {pairingByJudges[judge][i].project ? `Projet ${pairingByJudges[judge][i].project}` : "-"}
                        </div>
                    );
                }
            }
            table.push(<div className="period-row row projectsListItem">{cells}</div>);
        });

        return <div className="mt-1">{table}</div>;
    };

    HandlePeriodChange = e => {
        if (isNaN(e.currentTarget.value)) return;
        if (e.currentTarget.value < this.state.judgingPeriods) return;
        if (e.currentTarget.value > 9) return;
        this.setState({ totalPeriods: parseInt(e.currentTarget.value) }, () => {
            this.ResetPairing();
            this.CheckMinJudgesAmount();
        });
    };

    HandleJudgingAmountChange = e => {
        if (isNaN(e.currentTarget.value)) return;
        if (e.currentTarget.value < this.state.MIN_HIGHSCHOOL) return;
        if (e.currentTarget.value > this.state.DEFAULT_HIGHSCHOOL) return;
        this.setState({ judgingPeriods: parseInt(e.currentTarget.value) }, () => {
            this.ResetPairing();
            this.CheckMinJudgesAmount();
        });
    };

    HandleViewChange = e => {
        this.setState({
            [e.currentTarget.name]: e.currentTarget.checked
        });
    };

    OnFileSelect = e => {
        var reader = new FileReader();
        reader.onload = event => {
            let textData = reader.result;
            this.ImportJsonFile(textData);
        };
        reader.readAsText(e.target.files[0]);
    };

    RenderPairingWarning = () => {
        const { selectedFinal } = this.props.final;
        const { pairingFilenameInUse } = selectedFinal;
        const { showPairing } = this.state;
        return (
            <div>
                {showPairing === false && !isEmpty(pairingFilenameInUse) && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-danger">
                                Vous utiliser la version du pairage "{pairingFilenameInUse}" .
                                <br />
                                En continuant, vous allez écraser le pairage existant et les notes existantes s'il y a lieu.
                            </div>
                        </div>
                        <div className="col-md-12 py-3">
                            <button className="btn btn-main" onClick={this.ShowPairingModule}>
                                <h4>Afficher le module de pairage</h4>
                            </button>
                        </div>
                        <div className="col-md-12">
                            <hr />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    RenderExpImpModule = () => {
        const { pairing } = this.state.final;
        const { pairingByProjects } = pairing;
        return (
            <div className="row">
                <div className="col-md-12">
                    <h5>Exportation et importation d'un pairage</h5>
                </div>
                <div className="col-md-12">
                    <p className="alert alert-info">
                        <small>
                            Si vous avez modifié manuellement le pairage dans la vue par projets et que vous réenregistrez le pairage, ces
                            modifications seront également enregistrées et pourront être réimportées
                        </small>
                    </p>
                </div>
                <div className="col-md-12">
                    <button
                        className={classnames("btn btn-fonce mx-1", {
                            "btn-disabled": isEmpty(pairingByProjects)
                        })}
                        onClick={!isEmpty(pairingByProjects) ? this.HandleExportation : undefined}
                    >
                        Réexporter la version en cours
                    </button>
                    <label htmlFor="pairingJsonImport" className="btn btn-fonce mx-1 pt-1">
                        Importer une version précédente
                    </label>
                    <input
                        id="pairingJsonImport"
                        name="file"
                        type="file"
                        accept="application/json"
                        multiple={false}
                        onChange={this.OnFileSelect}
                        style={{ display: "none" }}
                    />
                </div>
            </div>
        );
    };

    ResetPairingInfos = () => {
        const pairingFilenameInUse = null;

        const pairingInfos = {
            _id: this.props.final.selectedFinal._id,
            pairing: {
                pairingByProjects: {},
                pairingByJudges: {}
            },
            pairingFilenameInUse,
            results: {},
            finalResults: [],
            reportsResults: {}
        };

        this.setState({ saveSuccess: null, pairingSuccess: null });
        this.props.SaveFinalPairing(pairingInfos, this.IsSaved);
    };

    ShowPairingModule = () => {
        this.setState({
            showPairing: true,
            saveSuccess: null,
            pairingSuccess: null
        });
    };

    RenderControlSection = () => {
        const { totalPeriods, ready } = this.state;

        return (
            <Fragment>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="totalPeriods">Nombre de périodes de jugement totales</label>
                        <input
                            type="number"
                            className="form-control"
                            name="totalPeriods"
                            id="totalPeriods"
                            min="0"
                            max="9"
                            value={totalPeriods}
                            onChange={this.HandlePeriodChange}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    RenderJugementAmountSection = () => {
        const { judgingPeriods, ready } = this.state;

        return (
            <Fragment>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="totalPeriods">Nombre de jugements pour la finale</label>
                        <input
                            type="number"
                            className="form-control"
                            name="judgingPeriods"
                            id="judgingPeriods"
                            min="3"
                            max="5"
                            value={judgingPeriods}
                            onChange={this.HandleJudgingAmountChange}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    // ==== END RENDER
    render() {
        const { selectedFinal } = this.props.final;
        const { longName, pairingFilenameInUse } = selectedFinal;
        const { ready, showPairing, pairing } = this.state;
        const id = this.props.match.params[0];
        const isPairingUsed = !isEmpty(pairingFilenameInUse);

        //let ready = this.RenderSetupReady () && !errors['JUDGES_AMOUNT'];
        return (
            <div>
                <FinalNav pageTitle="Finale - Infos" id={id} finalName={longName} />
                <div className="p-3">
                    <div id="linkContainer" />
                    <h1>Pairage juges/projets</h1>
                    {this.RenderPairingWarning()}
                    {this.RenderExpImpModule()}
                    <hr />
                    {(showPairing || !isPairingUsed) && (
                        <div>
                            {this.RenderDataValidation()}
                            <hr />
                            {ready && this.RenderPeriodChoice()}
                            <hr />
                            {(ready || isPairingUsed) && this.RenderPairing()}
                            <hr />
                            <h4>Erreurs</h4>
                            {this.RenderErrors()}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    final: state.final,
    judge: state.judge,
    project: state.project
});

const mapDispatchToProps = {
    SelectFinalById,
    SelectProjectsByFinalId,
    GetJudgesPwd,
    SaveFinalPairing,
    SetSpinner
};

export default connect(mapStateToProps, mapDispatchToProps)(PairingAlgo);
