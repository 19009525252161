import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {NavLink, Link, withRouter} from 'react-router-dom';
import FinalNav from '../pages/partials/FinalNav'
import { SelectFinalById } from '../../store/actions/finalActions'
import {
  SelectProjectsByFinalId,
  UpdateProject,
} from '../../store/actions/projectActions'

export class ProjectsRoomsList extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    this.props.SelectFinalById(this.props.match.params[0])
    this.props.SelectProjectsByFinalId(this.props.match.params[0])
  }

  OnRoomChange = (event) => {
    const input = event.currentTarget;

    const projectInfos = {
      _id: input.id,
      finalId:input.dataset.final,
      roomIndex: +input.dataset.index
    }
    this.props.UpdateProject(projectInfos)
  }

  //ReSET project
  RenderTableHead=(rooms)=>{
    const ths = rooms.map((element, index) => {
      return (
        <th className='col-md-3' key={ index} title={element.link}>
            salle #{+index+1}
        </th>
      )
    })

    return(
      <thead>
        <tr>
          <th>Projets</th>
          <th></th>
          {ths}
        </tr>
      </thead>
    );
  }
  RenderRow = ( project, rooms) => {
    const _id = project._id;
    const roomIndex = project.roomIndex;

    const inputs = rooms.map((element, index) => {
      const isChecked = roomIndex === index;
      const finalId = this.props.final.selectedFinal._id;

      return (
        <td className='col-md-3 align-content-center' key={_id + index}>
          <input type="radio" name={_id} id={_id} data-index={index} data-final={finalId} checked={isChecked} onChange={this.OnRoomChange} />
        </td>
      )
    })

    if(project.number === "" || project.number === null||project.information === undefined || project.information.projectInformation === undefined){
      return;
    }

    return (
      <tr key={_id}>
        <td>{project.number}</td>
        <td className="col">{project.information.projectInformation.title}</td>
        <Fragment>
          {inputs}
        </Fragment>
      </tr>
    )
  }

  render() {
    const id = this.props.match.params[0]
    const final = this.props.final.selectedFinal
    const { rooms = [] } = final
    const projectsList = this.props.project.projectsList
    
    const rows = projectsList.map((element, index, array) => {
      return this.RenderRow(element, rooms)
    })

    return (
      <div>
        <FinalNav
          pageTitle="Finale - Liste des projets"
          id={id}
          finalName={final.longName}
        />
         <div className='px-5'>
           <NavLink
              to={`/admin/finale/${id}/projets`}
              className="px-3 icon-button"
            >
             <h3><i className="fas fa-file-signature" /> Retour à la liste des projets</h3>
            </NavLink>
         </div>
        <div className="px-5 pb-5">
          <div className="col col-md-6">
            <h4>Liste des projets par salle</h4>
            <table className='room-table'>
              {this.RenderTableHead(rooms)}
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  final: state.final,
  project: state.project,
})

const mapDispatchToProps = {
  SelectFinalById,
  SelectProjectsByFinalId,
  UpdateProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsRoomsList)
