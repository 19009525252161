import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import { SelectProjectsByFinalId,UpdateProject } from '../../../store/actions/projectActions';

class FinalInfosUpdateModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      eventDate: '',
      shortName: '',
      longName: '',
      program: '',
      location: '',
      level: '',
    };
  }

  componentDidMount () {
    const finalInfos = this.props.finalInfos;
    const newState = {
      ...this.state,
      ...finalInfos,
    };
    newState.eventDate = moment (newState.eventDate).format ('YYYY-MM-DD');
    this.props.SelectProjectsByFinalId(finalInfos._id)
    this.setState (newState);
  }

  UpdateInfos = () => {
    const {projectsList=[]} = this.props.project;
    const {rooms=[]}=this.state;
    const {state} = this;

    state.eventDate = moment (state.eventDate).format ();
    this.props.ClearModal ();
    this.props.UpdateFinal (state);
    
    projectsList.map((project)=>{
      if(+project.roomIndex >= +rooms.length || project.roomIndex === undefined){
        const {_id} = project;
        const projectInfos = {_id, roomIndex : 0};
        this.props.UpdateProject(projectInfos);
      }
    })
  };

  HandleSelect = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleText = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleDate = e => {
    this.setState ({
      [e.currentTarget.name]: moment (e.currentTarget.value).format (
        'YYYY-MM-DD'
      ),
    });
  };

  HandleRoomChange = e => {
    const { rooms = [] } = this.state;
    const newRoomAmount = e.currentTarget.value;
    const range = Math.abs(rooms.length - newRoomAmount);

    if (newRoomAmount > rooms.length) {
      for (let index = 0; index < range; index++) {
        rooms[rooms.length + index] = { isOnline: false, link: "" }
      }
    } else if (newRoomAmount < rooms.length) {
      for (let index = 0; index < range; index++) {
        if(rooms.length>1){
          rooms.pop();
        }
      }
    }
    this.setState({ rooms });
  }

  RenderMainInfos = () => {
    const {eventDate, shortName, longName, location, level, rooms=[]} = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Informations</h4>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="eventDate">Date de l'événement</label>
          <input
            type="date"
            className="form-control"
            name="eventDate"
            id="eventDate"
            onChange={this.HandleDate}
            value={eventDate}
          />
        </div>
        <div className="col-md-6" />
        <div className="form-group col-md-12">
          <label htmlFor="longName">Nom de la finale</label>
          <input
            type="text"
            className="form-control"
            name="longName"
            id="longName"
            onChange={this.HandleText}
            value={longName}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="shortName">
            Nom de la finale pour mobile (60 caractères max.)
          </label>
          <input
            type="text"
            className="form-control"
            name="shortName"
            id="shortName"
            onChange={this.HandleText}
            value={shortName}
            maxLength={60}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="location">Lieu de la finale</label>
          <input
            type="text"
            className="form-control"
            name="location"
            id="location"
            onChange={this.HandleText}
            value={location}
          />
        </div>
        <div className="col-md-12 form-group">
          <h4>Volet</h4>
          <div className="custom-control custom-radio">
            <input
              className={classnames ('custom-control-input')}
              type="radio"
              name="level"
              id="elementaryLvl"
              value="elementary"
              onChange={this.HandleText}
              checked={level === 'elementary'}
            />
            <label className="custom-control-label" htmlFor="elementaryLvl">
              Volet primaire
            </label>
          </div>
          <div className="custom-control custom-radio">
            <input
              className={classnames ('custom-control-input mb-2')}
              type="radio"
              name="level"
              id="highschoolLvl"
              value="highschool"
              onChange={this.HandleText}
              checked={level === 'highschool'}
            />
            <label className="custom-control-label" htmlFor="highschoolLvl">
              Volet secondaire/collégial
            </label>
          </div>
        </div>
        <hr/>
        <div className="col-md-12 form-group">
          <h4>Nombre de salles de la finale</h4>
          <input type="number" name="room-amount" id="room-amount" onChange={this.HandleRoomChange} value={rooms.length}/>
          {this.RenderRooms()}
        </div>
      </div>
    );
  };

  RenderRooms = () => {
    const { rooms=[] } = this.state;
    const roomsInfos = rooms.map((room, index) => {
      const {isOnline = false,link=""}= room;

      return (<div className="col-md-12 form-group zoom-group" key={index} data-index={index}>
        <h6>Salle {+index + 1}</h6>
        <input type="checkbox" className="isOnline pr-1" name={"inOnline" + index} id={"inOnline" + index} onChange={this.OnZoomLinkChange} data-index={index} data-key="isOnline" checked={isOnline}/>&nbsp;
        <label htmlFor={"inOnline" + index}>Est en virtuel</label>
        <br></br>
        <label htmlFor={"zoom-" + index}>
          Numéro de la salle ou lien virtuel de la salle {index + 1} (Zoom, teams, etc)
        </label>
        <input
          type="text"
          className="form-control zoom-link"
          name={"zoom-" + index}
          id={"zoom-" + index}
          data-index={index}
          data-key="link"
          placeholder="Lien Zoom, Teams, etc"
          onChange={this.OnZoomLinkChange}
          value={link}
        />
        <hr></hr>
      </div>)
    })
    return <Fragment>
      {roomsInfos}
    </Fragment>
  }
  
OnZoomLinkChange = (event)=>{
  const { rooms=[] } = this.state;
  const input = event.currentTarget;
  const index = +input.dataset.index;
  const key = input.dataset.key;
  const value = input.type === "checkbox"? input.checked : input.value;

  rooms[index][key] = value;
  console.log(key, index, value);
  this.setState({rooms})
}

  render () {
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalUpdateFinal"
          id="modalFinal-btn"
        />
        <div
          className="modal fade"
          id="modalUpdateFinal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalFinalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="modalFinalLabel">
                  Modifier les informations de la finale
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModalBtn"
                  onClick={this.props.ClearModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr />
              <div className="modal-body" id="modalFinal-body">
                {this.RenderMainInfos ()}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={classnames ('btn btn-primary')}
                  onClick={this.UpdateInfos}
                >
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.ClearModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  project: state.project,
});

const mapDispatchToProps = {
  SelectProjectsByFinalId,
  UpdateProject
};

export default connect (mapStateToProps, mapDispatchToProps) (
  FinalInfosUpdateModal
);
