import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  GetJudgesPwd,
  SetJudgeNumber,
  SetStatus,
  DeleteJudge,
} from '../../../store/actions/judgeActions';
import isEmpty from '../../../validation/isEmpty';
import classnames from 'classnames';

class FinalViewJudgesInfos extends Component {
  componentDidMount = () => {
    //console.log(this.props);
    //this.props.GetJudgesPwd (this.props.final.selectedFinal._id);
  };

  componentWillUnmount = () => { };

  ToggleBlur = e => {
    const nextField = e.target.parentNode.parentNode.nextSibling;
    const blurState = nextField.classList.contains('pwd');
    document.querySelectorAll('div.judge-pwd').forEach(elem => {
      elem.classList.add('pwd');
    });

    blurState
      ? nextField.classList.remove('pwd')
      : nextField.classList.add('pwd');
  };

  HandleCheckStatus = e => {
    let status = e.currentTarget
      .checked
      ? 'Approuvé'
      : '';

    this.props.SetStatus(
      status,
      e.currentTarget.dataset.judgeid,
      this.props.final.selectedFinal._id
    );
  };

  SortNullNumber = () => {
    return (a, b) => {
      let alastname =
        a.information.generalInformation.lastName.toLowerCase() || '';
      let blastname =
        b.information.generalInformation.lastName.toLowerCase() || '';
      if (a.number === null && b.number === null) {
        if (alastname < blastname) {
          return -1;
        } else if (alastname > blastname) {
          return 1;
        }
        return 0;
      } else if (a.number === null && b.number !== null) {
        return 1;
      } else if (b.number === null && a.number !== null) {
        return -1;
      } else {
        return 0;
      }
    };
  };

  SortApproved = (a, b) => {
    return (a, b) => {
      let alastname =
        a.information.generalInformation.lastName.toLowerCase() || '';
      let blastname =
        b.information.generalInformation.lastName.toLowerCase() || '';

      let { status: astatus = "" } = a;
      let { status: bstatus = "" } = b;
      return bstatus.localeCompare(astatus) || a.number - b.number;
    }
  }

  UpdateModal = e => {
    const judge = this.props.judge.judgesList.find(j => {
      return j._id === e.currentTarget.dataset.judgeid;
    });
    if (judge) {
      this.props.ShowUpdateModal(judge);
    }
  };

  OnHandleClick = e => {
    //console.log(e.currentTarget.contentEditable);
    const target = e.currentTarget.parentNode.parentNode;
    const icon = target.querySelector('.edit-icon');
    const numberZone = target.querySelector('.number-zone');

    numberZone.isContentEditable === true
      ? this.EditModeOff(target, icon, numberZone)
      : this.EditModeOn(target, icon, numberZone);
  };

  EditModeOn = (target, icon, numberZone) => {
    this.ResetAll();
    //Change icon
    icon.classList.remove('fa-edit');
    icon.classList.add('fa-save');
    //Add border
    target.classList.add('edit-mode');
    //Change edit mode
    numberZone.contentEditable = 'true';
  };

  EditModeOff = (target, icon, numberZone) => {
    //Change icon
    icon.classList.remove('fa-save');
    icon.classList.add('fa-edit');
    //Remove border
    target.classList.remove('edit-mode');
    //Change edit mode
    numberZone.contentEditable = 'false';

    if (!parseInt(target.innerText)) {
      numberZone.innerText = '';
    }

    this.props.SetJudgeNumber(
      numberZone.innerText,
      target.dataset.judgeid,
      this.props.final.selectedFinal._id
    );
  };

  ResetAll = () => {
    const elems = document.querySelectorAll('.edit-mode');
    for (let elem of elems) {
      const icon = elem.querySelector('.edit-icon');
      const numberZone = elem.querySelector('.number-zone');
      this.EditModeOff(elem, icon, numberZone);
    }
  };

  CheckIfJudgeIsValid = information => {
    if (isEmpty(information)) {
      console.log('INFORMATION MISSING');

      return true;
    }

    const {
      generalInformation,
      judgingPreference,
      judgingExperience,
    } = information;

    if (
      isEmpty(generalInformation) ||
      isEmpty(judgingPreference) ||
      isEmpty(judgingExperience)
    ) {
      console.log('INFORMATION group MISSING');

      return true;
    }

    const { judgingEnglish, judgingFrench } = judgingExperience;
    if (
      (isEmpty(judgingEnglish) && isEmpty(judgingFrench)) ||
      (judgingEnglish === 'no' && judgingFrench === 'no')
    ) {
      console.log('no language');
      return true;
    }

    const {
      academicThird,
      academicSecond,
      academicFirst,
      categoryThird,
      categorySecond,
      categoryFirst,
      typeFirst,
      typeSecond,
    } = judgingPreference;
    if (
      isEmpty(academicThird) ||
      isEmpty(academicSecond) ||
      isEmpty(academicFirst) ||
      isEmpty(categoryThird) ||
      isEmpty(categorySecond) ||
      isEmpty(categoryFirst) ||
      isEmpty(typeFirst) ||
      isEmpty(typeSecond)
    ) {
      console.log('no infos');
      return true;
    }

    if (
      this.ReturnAccronym('classification', academicThird) === 'X' ||
      this.ReturnAccronym('classification', academicSecond) === 'X' ||
      this.ReturnAccronym('classification', academicFirst) === 'X' ||
      this.ReturnAccronym('category', categoryThird) === 'X' ||
      this.ReturnAccronym('category', categorySecond) === 'X' ||
      this.ReturnAccronym('category', categoryFirst) === 'X' ||
      this.ReturnAccronym('type', typeFirst) === 'X' ||
      this.ReturnAccronym('type', typeSecond) === 'X'
    ) {
      console.log('old infos');
      return true;
    }
    return false;
  };

  ReturnAccronym = (type, longName) => {
    let accronym = '';
    if (type === 'category') {
      switch (longName) {
        case 'human_social':
          accronym = 'SOC';
          break;
        case 'bio_health':
          accronym = 'BIO';
          break;
        case 'environment_ecosystems':
          accronym = 'ENV';
          break;
        case 'pure_sciences':
          accronym = 'PUR';
          break;
        case 'engineering_computer_robotics':
          accronym = 'ING';
          break;
        default:
          accronym = 'X';
          break;
      }
    } else if (type === 'type') {
      switch (longName) {
        case 'vulgarization':
          accronym = 'V';
          break;
        case 'experimentation':
          accronym = 'E';
          break;
        case 'conception':
          accronym = 'C';
          break;
        default:
          accronym = 'X';
          break;
      }
    } else if (type === 'classification') {
      switch (longName) {
        case 'Juvénile 1 (1er cycle du primaire)':
          accronym = 'P1';
          break;
        case 'Juvénile 2 (2e cycle du primaire)':
          accronym = 'P2';
          break;
        case 'Juvénile 3 (3e cycle du primaire)':
          accronym = 'P3';
          break;
        case 'Junior (secondaire 1)':
          accronym = 'J';
          break;
        case 'Intermédiaire (secondaire 2 et 3)':
          accronym = 'I';
          break;
        case 'Senior (secondaire  4 et 5)':
        case 'Senior (secondaire 4 et 5)':
          accronym = 'S';
          break;
        case 'Collégial 1 (1re et 2e année du cégep)':
          accronym = 'C1';
          break;
        case 'Collégial 2 (1re à la 3e année d’un programme technique du collégial ou une 3e année du double Dec)':
        case "Collégial 2 (1re à 3e année d'un programme technique du collégial ou une 3e année du double DEC)":
          accronym = 'C2';
          break;
        default:
          accronym = 'X';
          break;
      }
    }

    return accronym;
  };
  RenderLegend = information => {
    if (isEmpty(information)) return;

    const {
      judgingPreference = {
        categoryFirst: null,
        categorySecond: null,
        categoryThird: null,
        academicThird: null,
        academicSecond: null,
        academicFirst: null,
        typeFirst: null,
        typeSecond: null,
      },
      judgingExperience = {
        judgingFrench: null,
        judgingEnglish: null,
      },
    } = information;

    const {
      categoryFirst = null,
      categorySecond = null,
      categoryThird = null,
      academicFirst = null,
      academicSecond = null,
      academicThird = null,
      typeFirst = null,
      typeSecond = null,
    } = judgingPreference;

    const { judgingFrench = null, judgingEnglish = null } = judgingExperience;

    return (
      <div className="legend">
        <div className="legend-block">
          <div className="category">
            {categoryFirst
              ? `1-${this.ReturnAccronym('category', categoryFirst)}`
              : '1-X'}
          </div>
          <div className="category">
            {categorySecond
              ? `2-${this.ReturnAccronym('category', categorySecond)}`
              : '2-X'}
          </div>
          <div className="category">
            {categoryThird
              ? `3-${this.ReturnAccronym('category', categoryThird)}`
              : '3-X'}
          </div>
        </div>
        <div className="legend-block">
          <div className="classification">
            {academicFirst
              ? `1-${this.ReturnAccronym('classification', academicFirst)}`
              : '1-X'}
          </div>
          <div className="classification">
            {academicSecond
              ? `2-${this.ReturnAccronym('classification', academicSecond)}`
              : '2-X'}
          </div>
          <div className="classification">
            {academicThird
              ? `3-${this.ReturnAccronym('classification', academicThird)}`
              : '3-X'}
          </div>
        </div>
        <div className="legend-block">
          <div className="type">
            {typeFirst ? `1-${this.ReturnAccronym('type', typeFirst)}` : '1-X'}
          </div>
          <div className="type">
            {typeSecond
              ? `2-${this.ReturnAccronym('type', typeSecond)}`
              : '2-X'}
          </div>
        </div>
        <div className="legend-block">
          <div className="lang">
            {judgingFrench
              ? `Fr-${judgingFrench === 'yes' ? 'O' : 'N'}`
              : 'Fr-??'}
          </div>
          <div className="lang">
            {judgingEnglish
              ? `En-${judgingEnglish === 'yes' ? 'O' : 'N'}`
              : 'En-??'}
          </div>
        </div>
      </div>
    );
  };

  DeleteJudge = e => {
    const judgeId = e.currentTarget.dataset.judgeid;
    const finalId = this.props.final.selectedFinal._id;
    this.props.DeleteJudge({ judgeId, finalId });
  };

  render() {
    const judges = this.props.judge.judgesList.sort(this.SortApproved());
    const { selectedFinal = {} } = this.props.final;
    let pairingByJudges = {};

    if (selectedFinal && selectedFinal.pairing && selectedFinal.pairing.pairingByJudges) {
      pairingByJudges = selectedFinal.pairing.pairingByJudges
    }

    const infosList = judges.map(judge => {
      const { information, status, number, judgeId, username, _id, pwd } = judge;
      const { generalInformation } = information;
      const {
        phone = { phoneMobile: '' },
        firstName = '',
        lastName = '',
      } = generalInformation;
      const isInPairing = pairingByJudges.hasOwnProperty(number);
      const isInvalid = this.CheckIfJudgeIsValid(information);
      return (
        <div
          className={classnames('row judge-row pb-3', {
            'is-invalid': isInvalid,
          })}
          key={_id}
          title={isInPairing ? "Ce juge ne peut être désactivé car il est pairé à un projet" : ""}
        >
          <div className="col-md-2 judge-col username judge-number">
            <div
              className="col-md-12"
              data-number={number}
              data-judgeid={judgeId}
            >
              <div className="number-zone">{number}</div>
              <span>
                <i
                  onClick={this.OnHandleClick}
                  data-judgeid={judgeId}
                  className="p-2 icon-button edit-icon fas fa-edit"
                />
              </span>
            </div>
            <div className="col-md-12">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="status"
                  className="custom-control-input"
                  id={"status" + judgeId}
                  data-judgeid={judgeId}
                  checked={status === 'Approuvé' ? 'checked' : ''}
                  onChange={this.HandleCheckStatus}
                  disabled={isInPairing ? "disabled" : ""}
                />
                <label className="custom-control-label" htmlFor={"status" + judgeId}>
                  Est approuvé
                </label>
              </div>
            </div>

          </div>
          <div className="col-md-2 judge-col username px-1">
            <div className="col-md-12 row">
              {firstName}{' '}
              {lastName}
            </div>

            <div className="col-md-12 mt-2">
              <div className="">
                <small>Légende</small>
              </div>
              <div className="legend-container">
                {this.RenderLegend(information)}
              </div>
            </div>

          </div>
          <div className="col-md-1 judge-col username">
            {phone.phoneMobile}
          </div>
          <div className="col-md-3 judge-col username">{username}</div>
          <div className="col-md-2  judge-col">
            <div className="mr-3 px-3">
              <span>
                <i
                  onClick={this.ToggleBlur.bind(this)}
                  className="fas fa-eye"
                />
              </span>
            </div>
            <div className=" judge-pwd pwd">{pwd}</div>
          </div>
          <div
            className="col-md-1 judge-col text-center"
            onClick={this.UpdateModal}
            data-judgeid={_id}
          >
            <span><i className="fas fa-lg fa-edit icon-button" /></span>
          </div>
          <div
            className="col-md-1 judge-col text-center"
            onClick={this.DeleteJudge}
            data-judgeid={judgeId}
          >
            <span><i className="fas fa-lg fa-trash icon-button" /></span>
          </div>
        </div>
      );
    });
    return (
      <Fragment>
        <div className="row judge-row mb-3">
          <div className="col-md-2"><strong>Numéro</strong></div>
          <div className="col-md-2"><strong>Nom</strong></div>
          <div className="col-md-1"><strong>Téléphone mobile</strong></div>
          <div className="col-md-3"><strong>Nom d'utilisateur</strong></div>
          <div className="col-md-2"><strong>Mot de passe</strong></div>
          <div className="col-md-1"><strong>Modifier</strong></div>
          <div className="col-md-1"><strong>Supprimer</strong></div>
        </div>
        <div className="mb-3">{infosList}</div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  final: state.final,
  judge: state.judge,
});

export default connect(mapStateToProps, {
  GetJudgesPwd,
  SetJudgeNumber,
  SetStatus,
  DeleteJudge,
})(FinalViewJudgesInfos);
