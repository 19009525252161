import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import FinalNav from '../../pages/partials/FinalNav';

import {
  SelectFinalById,
  UpdateFinal,
} from '../../../store/actions/finalActions';
import {SelectProjectsByFinalId} from '../../../store/actions/projectActions';
import isEmpty from '../../../validation/isEmpty';
import {criterions} from '../../../enums/grids';
import classnames from 'classnames';

class FinalViewExportation extends Component {
  constructor (props) {
    super (props);
    this.state = {
      results: {},
      sortedResults: [],
      exportList: [],
      exequoByProject: {},
      exequoByResult: {},
      setup: false,
    };
  }

  componentDidMount = () => {
    this.props.SelectFinalById (this.props.match.params[0]);
    this.props.SelectProjectsByFinalId (this.props.match.params[0]);
  };

  componentDidUpdate (prevProps, prevState) {
    if (
      !isEmpty (this.props.project.projectsList) &&
      !isEmpty (this.props.final.selectedFinal) &&
      this.state.setup === false
    ) {
      this.CalculateResults (this.props.final.selectedFinal.results);
      this.setState (
        {
          setup: true,
        },
        () => {
          this.setState ({
            sortedResults: this.props.final.selectedFinal.finalResults,
            exportList: this.props.final.selectedFinal.finalResults,
          });
        }
      );
    }

    if (this.state.exportList !== prevState.exportList) {
      const {exportList} = this.state;

      this.CheckForEquality (exportList);
    }
  }

  FindProject = projectNumber => {
    if (isNaN (projectNumber)) return null;

    const foundProject = this.props.project.projectsList.filter (project => {
      return project.number == projectNumber;
    });
    return foundProject;
  };

  /**
   * Initiate result calculation, gets all projects with a number and add result 
   */
  CalculateResults = results => {
    //console.log(results);
    for (let project of this.props.project.projectsList) {
      if (!isEmpty (project.number)) {
        this.CalculateProjectResults (project.number, results[project.number]);
      }
    }
  };

  CalculateProjectResults = (projectNumber, projectResults) => {
    let resultArray = [];
    let internationalResultArray = [];

    // If no results is entered returns 0
    if (projectResults === undefined || isEmpty (projectResults)) {
      resultArray = [0, 0, 0, 0, 0];
      internationalResultArray = [0, 0, 0, 0, 0];
    } else {
      //Checks all the results total for a project
      resultArray = Object.keys (projectResults)
        .filter (key => {
          return (
            projectResults[key].total !== undefined &&
            projectResults[key].total !== null
          );
        })
        .map (key => {
          return projectResults[key].total;
        });

      //Checks all the results total for a project
      internationalResultArray = Object.keys (projectResults)
        .filter (key => {
          return (
            projectResults[key].totalInternational !== undefined &&
            projectResults[key].totalInternational !== null
          );
        })
        .map (key => {
          return projectResults[key].totalInternational;
        });
    }

    //if (isEmpty (resultArray)) return false;

    //Initialize result state
    const results = this.state.results;
    if (isEmpty (results[projectNumber])) {
      results[projectNumber] = {};
    }

    //Saves individual results in results state
    results[projectNumber].individualResults = projectResults;

    // ===========================================
    // ==========================================
    //CALCULATES RESULTS
    if (this.props.final.selectedFinal.level === 'highschool') {
      if(this.props.final.selectedFinal.judgingPeriods && this.props.final.selectedFinal.judgingPeriods<5){
        
        //Calc trimmed average
        results[projectNumber].finalAvgResults = this.CalcAvg (
          resultArray
        );
      }else{
        //Calc trimmed average
        results[projectNumber].finalAvgResults = this.CalcTrimmedAvg (
          resultArray
        );
      }
   
      //ADDS INTERNATIONAL RESULTS
      results[
        projectNumber
      ].finalInternationalResults = this.CalcInternationalResult (
        internationalResultArray
      );
    } else if (this.props.final.selectedFinal.level === 'elementary') {
      //Calc average
      results[projectNumber].finalAvgResults = this.CalcAvg (resultArray);
    }

    //SUPER EXPO-SCIENCES RESULTS
    if (this.props.final.selectedFinal.isSuperExpo) {
      //Get report results for project
      const reportResult = resultArray.length < 5
        ? 0
        : this.props.final.selectedFinal.reportsResults[projectNumber]
            ? this.props.final.selectedFinal.reportsResults[projectNumber]
                .reportResult
            : 0;

      // Add report result to other results
      results[projectNumber].finalAvgResults += parseFloat (reportResult);
      results[projectNumber].finalInternationalResults += parseFloat (
        reportResult
      );
    }
    //console.log (projectNumber, results[projectNumber]);

    //Saves to state and sort by default
    this.setState ({results}, () => {
      this.SortProjectByDefault ();
    });
  };

  /**
	 * Calculates regular average
	 * @return float
	 */
  CalcAvg = valueArray => {
    if (valueArray.length < 3) {
      return 0;
    }
    //console.log("error check", valueArray);
    return valueArray.reduce ((total, result, index, array) => {
      total += result;
      if (index === array.length - 1) {
        return total / array.length;
      } else {
        return total;
      }
    });
  };

  /**
	 * Calculates trimmed average without min and max value
   * FOR HIGHSCHOOL AND SUPER EXPO LEVELS
	 * @param valueArray
	 * @return float
	 */
  CalcTrimmedAvg = valueArray => {
    if (valueArray.length < 5) {
      return 0;
    }
    let array = valueArray;
    array = this.RemoveHighestJudge (array);
    array = this.RemoveLowestJudge (array);
    return this.CalcAvg (array);
  };

  /**
	 * Removes the min value of an array
	 * @param valueArray
	 * @return array    The valueArray without de min value
	 */
  RemoveLowestJudge = array => {
    array = array.sort ((a, b) => {
      return a - b;
    });
    array.shift ();
    return array;
  };

  /**
	 * Removes the max value of an array
	 * @param valueArray
	 * @return array    The valueArray without de max value
	 */
  RemoveHighestJudge = array => {
    array = array.sort ((a, b) => {
      return a - b;
    });
    array.pop ();
    return array;
  };

  /**
	 * Calculates the result for the international competition
	 * Doubles the animation results, final results on a total of 114...
   * FOR SUPER EXPO LEVEL
	 * @param valueArray
	 * @return float
	 */
  CalcInternationalResult = valueArray => {
    if (valueArray.length < 5) {
      return 0;
    }
    return valueArray.reduce ((total, result, index, array) => {
      total += result;
      if (index === array.length - 1) {
        return total / array.length;
      } else {
        return total;
      }
    });
  };

  SortByRanking = (results, ranking) => {
    //console.log (results, ranking);

    const sortedResults = ranking.map (rank => {
      return {[rank]: results[rank]};
    });

    return sortedResults;
  };

  SortProjectByDefault = () => {
    let results = this.state.results;
    if (isEmpty (results)) return;

    let ranking = [];
    ranking = Object.keys (results).sort ((a, b) => {
      if (isNaN (results[a].finalAvgResults)) return 1;
      if (isNaN (results[b].finalAvgResults)) return -1;
      return results[b].finalAvgResults - results[a].finalAvgResults;
    });

    this.ResetDirection ();

    this.setState ({
      sortedResults: this.SortByRanking (results, ranking),
      exportList: this.SortByRanking (results, ranking),
    });
  };

  SortProjectByProjectNumber = e => {
    let results = this.state.results;
    if (isEmpty (results)) return;

    const direction = parseInt (e.target.dataset.direction);

    let ranking = [];
    if (direction === 1) {
      ranking = Object.keys (results).sort ((a, b) => {
        return a - b;
      });
    } else if (direction === -1) {
      ranking = Object.keys (results).sort ((a, b) => {
        return b - a;
      });
    }

    this.ResetDirection ();
    e.target.dataset.direction = direction * -1;
    e.target.classList.add ('sort-active');
    this.FlipIconDirection (e.target);
    this.setState ({sortedResults: this.SortByRanking (results, ranking)});
  };

  SortProjectByAvg = e => {
    let results = this.state.results;
    if (isEmpty (results)) return;

    const direction = parseInt (e.target.dataset.direction);

    let ranking = [];
    if (direction === 1) {
      ranking = Object.keys (results).sort ((a, b) => {
        return results[a].finalAvgResults - results[b].finalAvgResults;
      });
    } else {
      ranking = Object.keys (results).sort ((a, b) => {
        return results[b].finalAvgResults - results[a].finalAvgResults;
      });
    }
    this.ResetDirection ();
    e.target.dataset.direction = direction * -1;
    e.target.classList.add ('sort-active');
    this.FlipIconDirection (e.target);
    this.setState ({sortedResults: this.SortByRanking (results, ranking)});
  };

  SortProjectByTrimmedAvg = e => {
    let results = this.state.results;
    if (isEmpty (results)) return;

    const direction = parseInt (e.target.dataset.direction);

    let ranking = [];
    if (direction === 1) {
      ranking = Object.keys (results).sort ((a, b) => {
        return (
          results[a].finalTrimmedAvgResults - results[b].finalTrimmedAvgResults
        );
      });
    } else {
      ranking = Object.keys (results).sort ((a, b) => {
        return (
          results[b].finalTrimmedAvgResults - results[a].finalTrimmedAvgResults
        );
      });
    }
    this.ResetDirection ();
    e.target.dataset.direction = direction * -1;
    e.target.classList.add ('sort-active');
    this.FlipIconDirection (e.target);
    this.setState ({sortedResults: this.SortByRanking (results, ranking)});
  };

  FlipIconDirection = elem => {
    elem.classList.remove ('sort-up', 'sort-down');
    elem.dataset.direction === 1
      ? elem.classList.add ('sort-up')
      : elem.classList.add ('sort-down');
  };

  ResetDirection = () => {
    document.querySelectorAll ('.btn-sort').forEach (elem => {
      elem.classList.remove ('sort-active');
      elem.dataset.direction = 1;
      this.FlipIconDirection (elem);
    });
  };

  //==============================
  // EXPORTATION SECTION
  MakeCSVFile = () => {
    const {exportList} = this.state;
    const {selectedFinal} = this.props.final;

    const data = exportList;
    if (isEmpty (data)) return;

    //SAVES TO DB
    selectedFinal.finalResults = exportList;
    this.props.UpdateFinal (selectedFinal);

    //CREATE FILE
    //For each result in sortedResults
    let csv = '';
    const headers = 'Rang;NoProjet;Note;Note Inter\n';
    csv += headers;
    data.map ((row, index) => {
      csv += `${index + 1};`;
      for (let key in row) {
        csv += `${key};`;
        csv += `${row[key].finalAvgResults};`;
        csv += `${row[key].finalInternationalResults ? row[key].finalInternationalResults : 0}\n`;
      }
      return true;
    });

    //FORMAT FILE
    let csvToDownload = document.createElement ('a');
    csvToDownload.style.display = 'none';
    csvToDownload.href = 'data:text/csv;charset=utf-8,' + encodeURI (csv);
    csvToDownload.target = '_blank';
    csvToDownload.download = `DonneeClassement-1.csv`;

    //DOWNLOAD FILE
    document.getElementById ('linkContainer').appendChild (csvToDownload);
    csvToDownload.click ();
  };

  CheckForEquality = list => {
    const {level, isSuperExpo, reportsResults} = this.props.final.selectedFinal;

    const exequoByResult = {};
    const exequoByProject = {};

    list.map ((result, index) => {
      for (let project in result) {
        const res = result[project].finalAvgResults.toFixed (5);
        if (isEmpty (exequoByResult[res])) {
          exequoByResult[res] = {};
        }

        const type = this.GetType (this.GetProject (project));

        const scientificValue = this.GetScientificValue (
          result[project],
          project,
          type,
          level
        );
        const communicationValue = this.GetCommunicationValue (
          result[project],
          project,
          type,
          level
        );

        const reportValue = isSuperExpo
          ? !isEmpty (reportsResults[project])
              ? reportsResults[project].reportResult
              : 0
          : this.GetReportValue (result[project], project, type, level);

        exequoByResult[res][project] = {
          result: result[project],
          rank: index,
          project,
          scientificValue,
          communicationValue,
          reportValue,
        };
      }
    });

    Object.keys (exequoByResult).map (res => {
      if (Object.keys (exequoByResult[res]).length > 1) {
        Object.keys (exequoByResult[res]).map (project => {
          exequoByProject[project] = exequoByResult[res][project];
        });
      }
    });

    this.setState ({exequoByResult, exequoByProject});
  };

  GetProject = projectNumber => {
    return this.props.project.projectsList.find (p => {
      if (isEmpty (p.number)) return false;

      return p.number.toString () === projectNumber.toString ();
    });
  };

  GetType = project => {
    const {information} = project;
    return information.projectInformation.type || null;
  };

  GetScientificValue = (results, project, type, level) => {
    if (
      isEmpty (results) ||
      isEmpty (results.individualResults) ||
      isEmpty (project) ||
      isEmpty (type) ||
      isEmpty (level)
    )
      return null;

    const crit = criterions[level][type].scientific;
    const {individualResults} = results;

    let arr = Object.keys (individualResults).map (judge => {
      const res = individualResults[judge].results;

      return Object.keys (res).reduce ((acc, key) => {
        if (crit.indexOf (key) !== -1) {
          return acc + res[key].total;
        }
        return acc + 0;
      }, 0);
    });

    const scientificValue = (arr.reduce ((acc, current) => {
      return acc + current;
    }) / arr.length).toFixed (5);

    return scientificValue;
  };

  GetCommunicationValue = (results, project, type, level) => {
    if (
      isEmpty (results) ||
      isEmpty (results.individualResults) ||
      isEmpty (project) ||
      isEmpty (type) ||
      isEmpty (level)
    )
      return null;

    const crit = criterions[level][type].communication;
    const {individualResults} = results;

    let arr = Object.keys (individualResults).map (judge => {
      const res = individualResults[judge].results;

      return Object.keys (res).reduce ((acc, key) => {
        if (crit.indexOf (key) !== -1) {
          return acc + res[key].total;
        }
        return acc + 0;
      }, 0);
    });

    const communicationValue = (arr.reduce ((acc, current) => {
      return acc + current;
    }) / arr.length).toFixed (5);

    return communicationValue;
  };

  GetReportValue = (results, project, type, level) => {
    if (
      isEmpty (results) ||
      isEmpty (results.individualResults) ||
      isEmpty (project) ||
      isEmpty (type) ||
      isEmpty (level) ||
      level === 'elementary'
    )
      return null;

    const crit = criterions[level][type].report;
    const {individualResults} = results;

    let arr = Object.keys (individualResults).map (judge => {
      const res = individualResults[judge].results;

      return Object.keys (res).reduce ((acc, key) => {
        if (crit.indexOf (key) !== -1) {
          return acc + res[key].total;
        }
        return acc + 0;
      }, 0);
    });

    const reportValue = (arr.reduce ((acc, current) => {
      return acc + current;
    }) / arr.length).toFixed (5);

    return reportValue;
  };

  MoveRankUp = e => {
    const {exportList, sortedResults} = this.state;
    const {rank} = e.currentTarget.dataset;

    if (parseInt (rank) + 1 >= exportList.length) {
      return;
    }
    const temp = exportList[parseInt (rank) + 1];
    exportList[parseInt (rank) + 1] = exportList[rank];
    exportList[parseInt (rank)] = temp;

    this.setState ({exportList, sortedResults: exportList});
  };

  MoveRankDown = e => {
    const {exportList, sortedResults} = this.state;
    const {rank} = e.currentTarget.dataset;

    if (parseInt (rank) - 1 < 0) {
      return;
    }
    const temp = exportList[parseInt (rank) - 1];
    exportList[parseInt (rank) - 1] = exportList[rank];
    exportList[parseInt (rank)] = temp;

    this.setState ({exportList, sortedResults: exportList});
  };

  RenderRow = (index, number, trimmedavg, finalAvgResults, title) => {
    const {level} = this.props.final.selectedFinal;
    const {exequoByProject, exequoByResult, exportList} = this.state;
    const isExequo = exequoByProject[number] ? exequoByProject[number] : null;
    const isExequoResult = exequoByResult[
      finalAvgResults.toFixed (5).toString ()
    ]
      ? exequoByResult[finalAvgResults.toFixed (5).toString ()]
      : null;
    const exequoBlockIndex = Object.keys (exequoByResult).indexOf (
      finalAvgResults.toFixed (5)
    );

    //Color groups to differenciate exequo
    let colorTab = ['#FFAAAA', '#FFCCCC', '#FFEEEE'];
    let color = exequoBlockIndex !== -1 && !isEmpty (isExequo)
      ? colorTab[exequoBlockIndex % 3]
      : null;

    let previousExequo = null;
    let nextExequo = null;

    //CHECK IS OTHER EXEQUO NEXT TO EACH OTHER
    if (
      (!isEmpty (isExequo), !isEmpty (isExequoResult) &&
        Object.keys (isExequoResult).length > 1)
    ) {
      if (parseInt (index) - 1 >= 0) {
        let previousRank = parseInt (index) - 1;
        let previousProject = exportList[previousRank];
        let project = Object.keys (previousProject)[0];
        previousExequo = isExequoResult[project]
          ? isExequoResult[project]
          : null;
      }
      if (parseInt (index) + 1 < exportList.length) {
        let nextRank = parseInt (index) + 1;
        let nextProject = exportList[nextRank];
        let project = Object.keys (nextProject)[0];
        nextExequo = isExequoResult[project] ? isExequoResult[project] : null;
      }
    }

    return (
      <div
        className={classnames ('col-md-12 row ranking-row my-1 py-2', {
          exequo: !isEmpty (isExequo),
        })}
        key={index}
        data-project={number}
        data-rank={index}
        style={{backgroundColor: color}}
      >
        <div className="col-md-1">
          {!isEmpty (isExequo) &&
            <div>
              {!isEmpty (previousExequo) &&
                <i
                  className="fas fa-arrow-up"
                  onClick={this.MoveRankDown}
                  data-project={number}
                  data-rank={index}
                />}
              {!isEmpty (nextExequo) &&
                <i
                  className="fas fa-arrow-down"
                  onClick={this.MoveRankUp}
                  data-project={number}
                  data-rank={index}
                />}
            </div>}
        </div>
        <div className="col-1 text-center">{index + 1}</div>
        <div className="col-4 text-center">
          Projet {number} | {title}
        </div>
        <div className="col-4 text-center">
          {isExequo &&
            <div>
              <p><i className="fas fa-exclamation-triangle pr-2" /> Égalité</p>
              <p>
                <small>Valeur scientifique: {isExequo.scientificValue}</small>
              </p>
              <p>
                <small>
                  Valeur communication: {isExequo.communicationValue}
                </small>
              </p>
              {level === 'highschool' &&
                <p>
                  <small>Valeur rapport écrit: {isExequo.reportValue}</small>
                </p>}
            </div>}
        </div>
        <div className="col-2 text-center">
          {trimmedavg === undefined
            ? <span>
                <i className="fas fa-exclamation-triangle" />
                {' '}
                Pas assez de jugements
              </span>
            : trimmedavg}
        </div>
      </div>
    );
  };

  render () {
    const id = this.props.match.params[0];
    const final = this.props.final.selectedFinal;
    const {setup} = this.state;
    const results = isEmpty (this.props.final.selectedFinal.results)
      ? {}
      : this.props.final.selectedFinal.results;

    //Prints results
    const list = this.state.sortedResults.map ((result, index) => {
      let number, trimmedavg, finalAvgResults;

      //Needs to map again because result contains an object with an index which is the project number
      Object.keys (result).map (key => {
        number = key;
        finalAvgResults = result[key].finalAvgResults;
        trimmedavg = !isNaN (result[key].finalAvgResults)
          ? result[key].finalAvgResults.toFixed (5)
          : undefined;
        return true;
      });

      if (isEmpty (number)) return;

      const project = this.FindProject (number);
      const title =
        project &&
        project[0] &&
        project[0].information.projectInformation.title;

      return this.RenderRow (index, number, trimmedavg, finalAvgResults, title);
    });

    return (
      <Fragment>
        <FinalNav
          pageTitle="Finale - Exportation"
          id={id}
          finalName={final.longName}
        />
        <div id="linkContainer" />
        <div className="container">
          <div className="row mx-auto mt-5">
            <div className="mx-auto text-center col-md-8">
              <h1>Classement</h1>
              <p className="alert alert-info">
                1. Appuyez toujours sur "Mettre à jour le classement" avant d'exporter pour avoir les informations les plus récentes sur les résultats.
                <br />
                2. Appuyez sur "Exporter en csv". Vous pourrez importer ce fichier dans le fichier "Donnees-prix.xlsx"
                <br />
                3. N'oubliez pas de désactiver l'accès à la finale pour terminer le jugement
              </p>
            </div>
            <div className="row my-3 col-12">
              <div className="mx-auto text-center">
                <button
                  type="button"
                  className="btn btn-reseau btn-lg btn-block p-3"
                  onClick={() => {
                    this.CalculateResults (results);
                  }}
                >
                  <span className="text-uppercase font-weight-bold">
                    Mettre à jour le classement
                  </span>
                </button>
              </div>
            </div>
            <div className="row my-3 col-12 border-bottom pb-4">
              <div className="mx-auto text-center">
                <button
                  type="button"
                  className="btn btn-reseau btn-success btn-block p-3"
                  onClick={this.MakeCSVFile}
                >
                  <span className="text-uppercase font-weight-bold">
                    Enregistrer le jugement et exporter en format CSV
                  </span>
                </button>
              </div>
            </div>

          </div>
          <div className="row col-md-12 mx-auto">
            <div className="col-1 text-center" />
            <div className="col-1 text-center">Rang</div>
            <div className="col-4 text-center">Numéro de projet</div>
            <div className="col-4 text-center">En cas d'égalité</div>
            <div className="col-2 text-center">Résultat</div>
          </div>
          <div className="row col-md-12 mx-auto mb-5">{list}</div>
        </div>
        <footer className="text-center">
          L'application
          {' '}
          <strong>Jugement mobile</strong>
          {' '}
          a été développée par le
          {' '}
          <a
            href="http://technoscience.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            <em>Réseau Technoscience</em>
          </a>
          , &copy; 2019.
        </footer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  final: state.final,
  project: state.project,
});

export default connect (mapStateToProps, {
  SelectFinalById,
  SelectProjectsByFinalId,
  UpdateFinal,
}) (FinalViewExportation);
