const regionsList = [
  {id: 1, name: 'Réseau Technoscience', accronym: 'RT'},
  {id: 2, name: 'Technoscience Abitibi-Témiscamingue', accronym: 'ABT'},
  {id: 3, name: 'Technoscience Côte-Nord', accronym: 'CN'},
  {id: 4, name: 'Technoscience Est du Québec', accronym: 'EDQ'},
  {id: 5, name: 'Technoscience Estrie', accronym: 'EST'},
  {id: 6, name: 'Technoscience Mauricie, Centre-du-Québec', accronym: 'MCQ'},
  {
    id: 7,
    name: 'Technoscience Région Métropolitaine - Montérégie',
    accronym: 'MTR',
  },
  {
    id: 8,
    name: 'Technoscience Région Métropolitaine - Montréal',
    accronym: 'MTL',
  },
  {
    id: 9,
    name: "Alliance pour l'enseignement de la science et de la technologie",
    accronym: 'AEST',
  },
  {id: 10, name: 'Technoscience Outaouais', accronym: 'OUT'},
  {
    id: 11,
    name: 'Technoscience Québec et Chaudière-Appalache',
    accronym: 'QCA',
  },
  {
    id: 12,
    name: 'Technoscience Région Métropolitaine - Rive-Nord',
    accronym: 'RN',
  },
  {id: 13, name: 'Technoscience Saguenay-Lac-Saint-Jean', accronym: 'SLSJ'},
  {id: 15, name: 'Finale québécoise', accronym: 'SUPER'},
];

export const getRegion = id => {
  return regionsList.find (r => {
    return r.id === id;
  });
};
export default regionsList;
