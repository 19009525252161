import React, { Component, Fragment } from "react";
import classnames from "classnames";
import isEmpty from "../../validation/isEmpty";
import moment from "moment";
export default class JudgeSwitchModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            judge: ""
        };
    }

    componentDidMount = () => {
        //console.log("mount", this.props);
    };

    GoToGrid = () => {
        const { project, judge, period, results } = this.props;
        this.props.GoToGrid(project, judge, period, results);
        this.props.ClearModal();
    };
    RemoveJudge = () => {
        const { project, judge, period, results } = this.props;
        this.MakeTXTFile(project, judge, period, results);

        this.props.RemoveJudge(project, period, judge);
        this.props.ClearModal();
    };

    MakeTXTFile = (project, judge, period, results) => {
        //console.log ('TXT', isEmpty (results));
        if (isEmpty(results)) return;
        if (isEmpty(results[project])) return;

        const res = results[project];

        //CREATE FILE
        //For each results of a project
        let txt = "";
        let titre = `======== SUPPRESSION DE JUGE ========\n`;
        titre += `======== JUGEMENT COURANT ========\n`;
        titre += `Projet ${project}\n`;
        titre += `Juge ${judge}\n`;
        titre += `Période ${period}\n`;
        titre += `================================\n\n`;

        let judgeTxt = "";
        if (res.hasOwnProperty(judge)) {
            judgeTxt += `Juge : ${judge} | Période : ${res[judge].period}\n`;

            for (const result in res[judge].results) {
                if (res[judge].results.hasOwnProperty(result)) {
                    //Print each result
                    judgeTxt += `${result} : ${res[judge].results[result].grade}/10 |  ${res[judge].results[result].total}/100 \n`;
                }
            }
            judgeTxt += "------------------------\n";
            judgeTxt += `Total:${res[judge].total}/100\n`;
            judgeTxt += "------------------------\n";
            judgeTxt += `Résultat complet:${res[judge].isComplete ? "Oui" : "Non"}\n\n`;
        }

        txt = titre + judgeTxt;

        //FORMAT FILE
        let txtToDownload = document.createElement("a");
        txtToDownload.style.display = "none";
        txtToDownload.href = "data:text/plain;charset=utf-8," + encodeURI(txt);
        txtToDownload.target = "_blank";
        txtToDownload.download = `SupJuge_Pro-${project}_J-${judge}_Per-${period}_${moment().format("YY-MM-DD_HH_mm")}.txt`;

        //DOWNLOAD FILE
        document.getElementById("linkContainer").appendChild(txtToDownload);
        txtToDownload.click();
    };

    SavePairing = () => {
        if (this.state.judge === "") return;
        const { project, period, judge } = this.props;

        this.props.SavePairing(project, period, this.state.judge, judge);
        this.props.ClearModal();
    };
    HandleSelect = e => {
        this.setState({ judge: e.currentTarget.value });
    };
    render() {
        const isInvalid = this.state.judge === "" ? true : false;
        const { judge } = this.props;

        const opts =
            this.props.list &&
            this.props.list.map(judge => {
                return (
                    <option key={judge.number} value={judge.number}>
                        {judge.number}
                    </option>
                );
            });
        return (
            <Fragment>
                <div id="linkContainer" />
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#modalJudge" id="modalJudge-btn" />
                <div
                    className="modal fade"
                    id="modalJudge"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalJudgeLabel"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h3 className="modal-title text-center" id="modalJudgeLabel">
                                    Projet {this.props.project} - Période {String.fromCharCode(64 + parseInt(this.props.period))}
                                </h3>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    id="closeModalBtn"
                                    onClick={this.props.ClearModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <hr />
                            <div className="modal-body" id="modalJudge-body">
                                <div className="mb-4 border-bottom">
                                    <h5 className="mx-auto">Saisir ou modifier un jugement</h5>
                                    <div className="my-2">
                                        {this.props.judge === undefined ? (
                                            <p>Aucun juge sélectionné</p>
                                        ) : (
                                            <button type="button" className="btn btn-accent2 btn-block py-2" onClick={this.GoToGrid}>
                                                Voir la fiche
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group mx-auto">
                                    <h5 className="mx-auto">
                                        Changer de juge
                                        <small className="pl-3">
                                            <span>
                                                <i className="fas fa-exclamation-circle" />
                                            </span>{" "}
                                            Attention, cela effacera un jugement enregistré
                                        </small>
                                    </h5>
                                    <label htmlFor="">Liste des juges disponibles</label>
                                    <select className="custom-select" name="judge" id="judge" onChange={this.HandleSelect} value={this.state.judge}>
                                        <option value="">
                                            {this.props.list.length === 0 ? "Aucun juge disponible pour cette période" : "Choisir un juge disponible"}
                                        </option>{" "}
                                        />
                                        {opts}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {judge && (
                                    <button type="button" className="btn btn-danger mr-auto" onClick={this.RemoveJudge}>
                                        <span>
                                            <i className="fas fa-trash" />
                                        </span>{" "}
                                        Supprimer le juge pour cette période
                                    </button>
                                )}
                                {this.props.list.length > 0 && (
                                    <button
                                        type="button"
                                        className={classnames("btn btn-secondary", {
                                            "btn-disabled": isInvalid
                                        })}
                                        onClick={this.SavePairing}
                                    >
                                        {isInvalid ? "Choisir un juge dans la liste" : "Modifier le juge"}
                                    </button>
                                )}
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.ClearModal}>
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
