import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
  SelectProjectsByFinalId,
  SetProjectNumber,
} from '../../../store/actions/projectActions';
import {SaveFinalPairing} from '../../../store/actions/finalActions';
import isEmpty from '../../../validation/isEmpty';
import classnames from 'classnames';
import {
  categories,
  classifications,
  types,
} from '../../../enums/classifications_categories';

export class FinalViewProjectsInfos extends Component {
  componentDidMount = () => {
    //this.props.SelectProjectsByFinalId (this.props.match.params[0]);
  };

  SortNullNumber = () => {
    return (a, b) => {
      if (isEmpty (a.number) && isEmpty (b.number)) {
        return 0;
      } else if (isEmpty (a.number) && !isEmpty (b.number)) {
        return 1;
      } else if (!isEmpty (a.number) && isEmpty (b.number)) {
        return -1;
      } else {
        if (a.number < b.number) {
          return -1;
        }
        if (a.number > b.number) {
          return 1;
        }
        return 0;
      }
    };
  };

  OnHandleClick = e => {
    //console.log(e.currentTarget.contentEditable);
    const target = e.currentTarget.parentNode.parentNode;
    const icon = target.querySelector ('.edit-icon');
    const numberZone = target.querySelector ('.number-zone');

    numberZone.isContentEditable === true
      ? this.EditModeOff (target, icon, numberZone)
      : this.EditModeOn (target, icon, numberZone);
  };

  ResetAll = () => {
    const elems = document.querySelectorAll ('.edit-mode');
    for (let elem of elems) {
      const icon = elem.querySelector ('.edit-icon');
      const numberZone = elem.querySelector ('.number-zone');
      this.EditModeOff (elem, icon, numberZone);
    }
  };

  ResetPairingInfos = () => {
    const pairingFilenameInUse = null;

    const pairingInfos = {
      _id: this.props.final.selectedFinal._id,
      pairing: {
        pairingByProjects: {},
        pairingByJudges: {},
      },
      pairingFilenameInUse,
      results: {},
      finalResults: [],
      reportsResults: {},
    };
    this.props.SaveFinalPairing (pairingInfos, this.IsSaved);
  };

  IsSaved = (isSaved, pairing) => {
    return null;
  };
  EditModeOn = (target, icon, numberZone) => {
    this.ResetAll ();
    //Change icon
    icon.classList.remove ('fa-edit');
    icon.classList.add ('fa-save');
    //Add border
    target.classList.add ('edit-mode');
    //Change edit mode
    numberZone.contentEditable = 'true';
  };

  EditModeOff = (target, icon, numberZone) => {
    //Change icon
    icon.classList.remove ('fa-save');
    icon.classList.add ('fa-edit');
    //Remove border
    target.classList.remove ('edit-mode');
    //Change edit mode
    numberZone.contentEditable = 'false';
    if (!parseInt (target.innerText)) {
      numberZone.innerText = '';
    }
    if (this.props.final.selectedFinal.pairingFilenameInUse !== null) {
      this.ResetPairingInfos ();
    }
    this.props.SetProjectNumber (
      numberZone.innerText,
      target.dataset.projectid,
      this.props.final.selectedFinal._id
    );
  };

  UpdateModal = e => {
    const project = this.props.project.projectsList.find (p => {
      return (
        p._id.toString () === e.currentTarget.dataset.projectid.toString ()
      );
    });

    //console.log (project);
    if (project) {
      this.props.ShowUpdateModal (project);
    }
  };

  render () {
    const projects = this.props.project.projectsList.sort (
      this.SortNullNumber ()
    );

    const infosList = projects.map (project => {
      const {
        _id,
        information = {
          projectInformation: {
            category: '',
            languageEnglish: '',
            languageFrench: '',
            title: '',
            type: '',
          },
        },
        classification = '',
        projectId,
        number = '',
      } = project;
      //if (information === undefined) return;

      const {projectInformation = '', participants = ''} = information;

      //if (projectInformation === undefined) return;

      const {
        category = '',
        languageEnglish = '',
        languageFrench = '',
        title = '',
        type = '',
      } = projectInformation;

      const isInvalid = false; //this.CheckIfJudgeIsValid (information) ||

      return (
        <div
          className={classnames ('row judge-row pb-3', {
            'is-invalid': isInvalid,
          })}
          key={_id}
        >
          <div className="col-md-1 judge-col username judge-number row">
            <div
              className="col-md-12"
              data-number={number}
              data-projectid={projectId}
            >
              <div className="number-zone">{project.number}</div>
              <span>
                <i
                  onClick={this.OnHandleClick}
                  data-projectid={projectId}
                  className="p-2 icon-button edit-icon fas fa-edit"
                />
              </span>
            </div>
          </div>
          <div className="col-md-3 judge-col ">
            <p>{title}</p>
          </div>
          <div className="col-md-1 judge-col ">
            <p>{types[type]}</p>
          </div>
          <div className="col-md-2 judge-col ">
            <p>{classification}</p>
          </div>
          <div className="col-md-2 judge-col ">
            <p>{categories[category]}</p>
          </div>
          <div className="col-md-1 judge-col ">
            <p>
              {languageFrench === 'yes' ? 'O' : 'N'}
            </p>
          </div>
          <div className="col-md-1 judge-col ">
            <p>
              {languageEnglish === 'yes' ? 'O' : 'N'}
            </p>
          </div>
          <div
            className="col-md-1 judge-col text-center"
            onClick={this.UpdateModal}
            data-projectid={_id}
          >
            <span><i className="fas fa-lg fa-edit icon-button" /></span>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <div className="row judge-row mb-3">
          <div className="col-md-1"><strong>Numéro</strong></div>
          <div className="col-md-3"><strong>Titre</strong></div>
          <div className="col-md-1"><strong>Type</strong></div>
          <div className="col-md-2"><strong>Classe</strong></div>
          <div className="col-md-2"><strong>Catégorie</strong></div>
          <div className="col-md-1"><strong>Jugement en français</strong></div>
          <div className="col-md-1"><strong>Jugement en anglais</strong></div>
          <div className="col-md-1"><strong>Modifier</strong></div>
        </div>
        <div className="mb-3">{infosList}</div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  final: state.final,
  project: state.project,
});

const mapDispatchToProps = {
  SelectProjectsByFinalId,
  SetProjectNumber,
  SaveFinalPairing,
};

export default connect (mapStateToProps, mapDispatchToProps) (
  FinalViewProjectsInfos
);
