import {
  GET_ERRORS,
  GET_VALIDATION_ERRORS,
  CLEAR_ERRORS,
} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {...initialState, [action.payload.type]: action.payload};
    case GET_VALIDATION_ERRORS:
      return action.payload;
    case CLEAR_ERRORS:
      return initialState;
    default:
      return state;
  }
}
