import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {
  categories,
  classifications,
  type,
  types,
} from '../../enums/classifications_categories';

class UpdateJudgeModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      number: '',
      username: '',
      pwd: '',
      information: {
        generalInformation: {
          firstName: '',
          lastName: '',
          email: '',
          address: {
            postalCode: '',
          },
          phone: {
            phoneMobile: '',
          },
        },
        judgingPreference: {
          academicThird: '',
          academicSecond: '',
          academicFirst: '',
          typeSecond: '',
          typeFirst: '',
          categoryThird: '',
          categorySecond: '',
          categoryFirst: '',
        },
        judgingExperience: {
          judgingFrench: '',
          judgingEnglish: '',
        },
      },
    };
  }

  componentDidMount () {
    const judgeInfos = this.props.judge;
    const newState = {
      ...this.state,
      ...judgeInfos,
    };
    this.setState (newState);
  }

  UpdateInfos = () => {
    this.props.UpdateJudgeInfos (this.state);
  };

  HandleSelect = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleCheck = e => {
    const {information} = this.state;
    if (information.judgingExperience === undefined) {
      information.judgingExperience = {};
    }

    information.judgingExperience[e.currentTarget.name] = e.currentTarget
      .checked
      ? 'yes'
      : 'no';
    this.setState ({information});
  };

  HandleText = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleGeneralInformation = e => {
    const {information} = this.state;
    if (information.judgingPreference === undefined) {
      information.generalInformation = {};
    }
    information.generalInformation[e.currentTarget.name] =
      e.currentTarget.value;
    this.setState ({information});
  };
  HandleJudgingExperience = e => {
    const {information} = this.state;
    if (information.judgingExperience === undefined) {
      information.judgingExperience = {};
    }
    information.judgingExperience[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({information});
  };
  HandleJudgingPreference = e => {
    const {information} = this.state;
    if (information.judgingPreference === undefined) {
      information.judgingPreference = {};
    }
    information.judgingPreference[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({information});
  };

  RenderMainInfos = () => {
    const {username, number, pwd, information} = this.state;
    const {generalInformation} = information;
    const {firstName, lastName} = generalInformation;
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Informations</h4>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="">Numéro de juge</label>
          <input
            type="text"
            className="form-control"
            name="number"
            id="number"
            onChange={this.HandleText}
            value={number}
          />
        </div>
        <div className="col-md-6" />
        <div className="form-group col-md-6">
          <label htmlFor="">Nom d'utilisateur</label>
          <input
            type="text"
            className="form-control"
            name="username"
            id="username"
            onChange={this.HandleText}
            value={username}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="pwd">Mot de passe</label>
          <input
            type="text"
            className="form-control"
            name="pwd"
            id="pwd"
            onChange={this.HandleText}
            value={pwd}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="">Prénom</label>
          <input
            type="text"
            className="form-control"
            name="firstName"
            id="firstName"
            onChange={this.HandleGeneralInformation}
            value={firstName}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="">Nom de famille</label>
          <input
            type="text"
            className="form-control"
            name="lastName"
            id="lastName"
            onChange={this.HandleGeneralInformation}
            value={lastName}
          />
        </div>
      </div>
    );
  };

  RenderCategoryPreferences = () => {
    const {information} = this.state;
    const {
      judgingPreference = {
        categoryFirst: '',
        categorySecond: '',
        categoryThird: '',
      },
    } = information;

    const {
      categoryFirst = '',
      categorySecond = '',
      categoryThird = '',
    } = judgingPreference;

    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Préférence de catégories</h4>
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="categoryFirst">Premier choix</label>
          <select
            className="custom-select"
            name="categoryFirst"
            id="categoryFirst"
            onChange={this.HandleJudgingPreference}
            value={categoryFirst}
          >
            <option value="">Choisir</option>
            {this.RenderCategoryOptions ()}
          </select>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="categorySecond">Deuxième choix</label>
          <select
            className="custom-select"
            name="categorySecond"
            id="categorySecond"
            onChange={this.HandleJudgingPreference}
            value={categorySecond}
          >
            <option value="">Choisir</option>
            {this.RenderCategoryOptions ()}
          </select>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="categoryThird">Troisième choix</label>
          <select
            className="custom-select"
            name="categoryThird"
            id="categoryThird"
            onChange={this.HandleJudgingPreference}
            value={categoryThird}
          >
            <option value="">Choisir</option>
            {this.RenderCategoryOptions ()}
          </select>
        </div>
      </div>
    );
  };

  RenderCategoryOptions = () => {
    const options = Object.keys (categories).map (cat => {
      return <option key={cat} value={cat}>{categories[cat]}</option>;
    });

    return options;
  };

  RenderAcademicPreferences = () => {
    const {information} = this.state;
    const {
      judgingPreference = {
        categoryFirst: '',
        categorySecond: '',
        categoryThird: '',
        academicThird: '',
        academicSecond: '',
        academicFirst: '',
        typeFirst: '',
        typeSecond: '',
      },
    } = information;

    const {
      academicFirst = '',
      academicSecond = '',
      academicThird = '',
    } = judgingPreference;

    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Préférence de classes</h4>
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="academicFirst">Premier choix</label>
          <select
            className="custom-select"
            name="academicFirst"
            id="academicFirst"
            onChange={this.HandleJudgingPreference}
            value={academicFirst}
          >
            <option value="">Choisir</option>
            {this.RenderAcademicOptions ()}
          </select>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="academicSecond">Deuxième choix</label>
          <select
            className="custom-select"
            name="academicSecond"
            id="academicSecond"
            onChange={this.HandleJudgingPreference}
            value={academicSecond}
          >
            <option value="">Choisir</option>
            {this.RenderAcademicOptions ()}
          </select>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="academicThird">Troisième choix</label>
          <select
            className="custom-select"
            name="academicThird"
            id="academicThird"
            onChange={this.HandleJudgingPreference}
            value={academicThird}
          >
            <option value="">Choisir</option>
            {this.RenderAcademicOptions ()}
          </select>
        </div>
      </div>
    );
  };

  RenderAcademicOptions = () => {
    const options = Object.keys (classifications).map (key => {
      return (
        <option key={key} value={classifications[key]}>
          {classifications[key]}
        </option>
      );
    });

    return options;
  };

  RenderTypePreferences = () => {
    const {information} = this.state;
    const {
      judgingPreference = {
        typeFirst: '',
        typeSecond: '',
      },
    } = information;

    const {typeFirst = '', typeSecond = ''} = judgingPreference;

    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Préférence de type de projet</h4>
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="typeFirst">Premier choix</label>
          <select
            className="custom-select"
            name="typeFirst"
            id="typeFirst"
            onChange={this.HandleJudgingPreference}
            value={typeFirst}
          >
            <option value="">Choisir</option>
            {this.RenderTypeOptions ()}
          </select>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="typeSecond">Deuxième choix</label>
          <select
            className="custom-select"
            name="typeSecond"
            id="typeSecond"
            onChange={this.HandleJudgingPreference}
            value={typeSecond}
          >
            <option value="">Choisir</option>
            {this.RenderTypeOptions ()}
          </select>
        </div>
      </div>
    );
  };

  RenderTypeOptions = () => {
    const options = Object.keys (types).map (key => {
      return <option key={key} value={key}>{types[key]}</option>;
    });

    return options;
  };

  RenderLanguagePreferences = () => {
    const {information} = this.state;
    const {
      judgingExperience = {
        judgingFrench: '',
        judgingEnglish: '',
      },
    } = information;

    const {judgingFrench = '', judgingEnglish = ''} = judgingExperience;

    return (
      <div className="row ">
        <div className="col-md-12 ">
          <h4>Préférence de langue</h4>
        </div>
        <div className="row col-md-12 px-5">
          <div className="custom-control custom-checkbox col-md-6">
            <input
              type="checkbox"
              name="judgingFrench"
              className="custom-control-input"
              id="judgingFrench"
              checked={judgingFrench === 'yes' ? 'checked' : ''}
              onChange={this.HandleCheck}
            />
            <label className="custom-control-label" htmlFor="judgingFrench">
              Le projet peut être jugé en français
            </label>
          </div>
          <div className="custom-control custom-checkbox col-md-6">
            <input
              type="checkbox"
              className="custom-control-input"
              name="judgingEnglish"
              id="judgingEnglish"
              checked={judgingEnglish === 'yes' ? 'checked' : ''}
              onChange={this.HandleCheck}
            />
            <label className="custom-control-label" htmlFor="judgingEnglish">
              Le projet peut être jugé en anglais
            </label>
          </div>
        </div>

      </div>
    );
  };

  render () {
    let isInvalid = false;

    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalUpdateJudge"
          id="modalJudge-btn"
        />
        <div
          className="modal fade"
          id="modalUpdateJudge"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalJudgeLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="modalJudgeLabel">
                  Modifier les informations d'un juge
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModalBtn"
                  onClick={this.props.ClearModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr />
              <div className="modal-body" id="modalJudge-body">
                {this.RenderMainInfos ()}
                <hr />
                {this.RenderCategoryPreferences ()}
                <hr />
                {this.RenderAcademicPreferences ()}
                <hr />
                {this.RenderTypePreferences ()}
                <hr />
                {this.RenderLanguagePreferences ()}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={classnames ('btn btn-primary', {
                    'btn-disabled': isInvalid,
                  })}
                  onClick={this.UpdateInfos}
                >
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.ClearModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect (mapStateToProps, mapDispatchToProps) (UpdateJudgeModal);
