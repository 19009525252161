import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getRegion} from '../../enums/regions';

/**
 * Class showing an final item from the list
 * @props function  ToggleActivationFinal(id)   Activates the final
 * @props object    final               the final infos
 */
class FinalListItem extends Component {
  ToggleFinalActivation = e => {
    if (this.props.final !== null && this.props.final !== undefined) {
      this.props.ToggleFinalActivation (this.props.final._id);
    } else {
      console.log ('Err ToggleActivationFinal():No final found');
    }
  };
  GoTo = address => {
    this.props.history.push (address);
  };
  RenderDate = date => {
    const renderedDate = new Date (
      Date.parse (date)
    ).toLocaleDateString ('fr-CA', {
      timeZone: 'UTC',
    });
    return renderedDate;
  };
  render () {
    const {
      _id,
      longName,
      isActive,
      level,
      eventDate,
      region,
    } = this.props.final;
    return (
      <div className="row final-row border-bottom">
        <div className="col-md-11 row">
          <p className="col-md-1">
            <strong>{getRegion (region).accronym}</strong>
          </p>
          <p className="col-md-1">
            <strong>
              <small>
                {level === 'elementary'
                  ? 'Prim'
                  : level === 'highschool' ? 'Sec/Coll' : null}
                {' '}
              </small>
            </strong>
          </p>
          <p className="col-md-8">
            {longName}-{' '}
            {isActive
              ? <span className="text-info">Actif</span>
              : <span className="text-muted">Inactif</span>}
          </p>
          <p className="col-md-1">
            <strong>{this.RenderDate (eventDate)}</strong>
          </p>
        </div>
        <div className="col">
          <button
            className="icon-button pr-3"
            onClick={this.ToggleFinalActivation}
          >
            {isActive
              ? <i className="fas fa-2x fa-toggle-on text-info" />
              : <i className="fas fa-2x fa-toggle-off text-muted" />}
          </button>

          <Link to={`/admin/finale/${_id}/infos`} className="icon-button">
            <i className="fas fa-2x fa-sign-out-alt" />
          </Link>
        </div>
      </div>
    );
  }
}

FinalListItem.propTypes = {
  final: PropTypes.object.isRequired,
  ToggleFinalActivation: PropTypes.func.isRequired,
};

export default withRouter (FinalListItem);
