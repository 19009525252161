import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import AdminNav from '../pages/partials/AdminNav';
import FinalList from './FinalList';
import FinalJSONImport from './finalImportation/FinalJSONImport';
import InitPwdForm from '../admin/auth/InitPwd';
import {ClearJudgesList} from '../../store/actions/judgeActions';
import {ClearProjectsList} from '../../store/actions/projectActions';
import {ClearSelectedFinal} from '../../store/actions/finalActions';
class AdminListPage extends Component {
  componentDidMount = () => {
    this.props.ClearJudgesList ();
    this.props.ClearProjectsList ();
    this.props.ClearSelectedFinal ();
  };

  render () {
    const admin = this.props.auth.user;
    return (
      <Fragment>
        {admin.newAdmin
          ? <InitPwdForm />
          : <Fragment>
              <AdminNav pageTitle="Vue de l'ensemble des finales" />
              <div className="">
                <div className="row py-5">
                  <div className="col-md-4 spliter">
                    <FinalJSONImport />
                  </div>
                  <div className="col-md-8">
                    <FinalList />
                  </div>
                </div>
              </div>
              <footer className="text-center">
                L'application
                {' '}
                <strong>Jugement mobile</strong>
                {' '}
                a été développée par le
                {' '}
                <a
                  href="http://technoscience.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <em>Réseau Technoscience</em>
                </a>
                , &copy; 2019.
              </footer>
            </Fragment>}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect (mapStateToProps, {
  ClearJudgesList,
  ClearProjectsList,
  ClearSelectedFinal,
}) (AdminListPage);
