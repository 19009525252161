import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import FinalNav from '../../pages/partials/FinalNav';
import FinalInfosUpdateModal from './FinalInfosUpdateModal';

import {
  SelectFinalById,
  ToggleFinalActivation,
  UpdateFinal,
  DeleteFinal,
} from '../../../store/actions/finalActions';

import {
  DeleteAllFinalJudges,
  GetJudgesPwd,
  CreateJudge,
} from '../../../store/actions/judgeActions';

import {
  DeleteAllFinalProjects,
  SelectProjectsByFinalId,
  CreateProject,
} from '../../../store/actions/projectActions';

import isEmpty from '../../../validation/isEmpty';
import Regions, {getRegion} from '../../../enums/regions';

import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import moment from 'moment';

class FinalViewInfos extends Component {
  constructor (props) {
    super (props);

    this.state = {
      modal: '',
    };
  }

  ShowUpdateFinalModal = admin => {
    const modal = (
      <FinalInfosUpdateModal
        finalInfos={this.props.final.selectedFinal}
        ClearModal={this.ClearModal}
        UpdateFinal={this.props.UpdateFinal}
      />
    );
    this.setState ({modal}, () => {
      //Show modal box
      document.getElementById ('modalFinal-btn').click ();
    });
  };

  ClearModal = () => {
    document.getElementById ('closeModalBtn').click ();
    this.setState ({modal: ''});
  };
  componentDidMount = () => {
    this.props.SelectFinalById (this.props.match.params[0]);
    this.props.SelectProjectsByFinalId (this.props.match.params[0]);
    this.props.GetJudgesPwd (this.props.match.params[0]);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.final !== this.props.final &&
      isEmpty (prevProps.final.selectedFinal)
    ) {
      this.props.GetJudgesPwd (this.props.final.selectedFinal._id);
    }
    if (prevProps.final.finalList !== this.props.final.finalList) {
      this.props.SelectFinalById (this.props.match.params[0]);
    }
  };

  ImportJsonFile = jsonFile => {
    try {
      const json = JSON.parse (jsonFile);
      console.log (json);
      if (isEmpty (json) || isEmpty (json.final)) return;

      this.props.UpdateFinal (json.final, this.IsSaved);
      json.judgesList.map (j => {
        this.props.CreateJudge (j);
      });
      json.projectsList.map (p => {
        this.props.CreateProject (p);
      });
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.log (error);
      } else {
        console.log (error);
      }
    }
  };

  OnFileSelect = e => {
    var reader = new FileReader ();
    reader.onload = event => {
      let textData = reader.result;
      this.ImportJsonFile (textData);
    };
    reader.readAsText (e.target.files[0]);
  };

  ArchiveFinal = () => {
    const {selectedFinal} = this.props.final;
    const {
      pairing,
      region,
      level,
      pairingFilenameInUse,
      _id,
      reportsResults,
      finalResults,
      results,
    } = selectedFinal;
    const {pairingByJudges, pairingByProjects} = pairing;
    const {projectsList} = this.props.project;
    const {judgesList} = this.props.judge;

    const regionName = getRegion (region);
    const levelName = level === 'highschool'
      ? 'Sec'
      : level === 'elementary' ? 'Prim' : '';
    const timestamp = moment ().format ('YY-MM-DD_HH-mm-ss');
    const pairingFileName = !isEmpty (pairingFilenameInUse)
      ? pairingFilenameInUse
      : 'Pairage';

    const importationFile = {final: selectedFinal, judgesList, projectsList};
    if (!isEmpty (pairingByJudges) && !isEmpty (pairingByProjects)) {
      importationFile.final.pairingFilenameInUse = pairingFileName;
    }
    // ===== CREATE ZIP
    const zipname = `Archive_XSC__${regionName.accronym}_${levelName}_${timestamp}`;

    // ===== BUILD ZIP ARCHITECTURE
    const zip = new JSZip ();
    const pourImportation = zip.folder ('POUR_REIMPORTER_UNE_FINALE');
    pourImportation.file (
      'FINALE_POUR_REIMPORTATION.json',
      JSON.stringify (importationFile)
    );
    // ===== BACKUP FINAL INFOS
    const finale = zip.folder ('finale');
    finale.file (`finaleInfos.json`, JSON.stringify (selectedFinal));
    finale.file (`jugesInfos.json`, JSON.stringify (judgesList));
    finale.file (`projetsInfos.json`, JSON.stringify (projectsList));

    //========== CREAtION OF CSVs
    const projectsCSV = this.ConvertPairingToCSV (
      pairingByProjects,
      'Projet',
      'judge'
    );
    const judgesCSV = this.ConvertPairingToCSV (
      pairingByJudges,
      'Juge',
      'project'
    );
    const reportsResultsCSV = this.ConvertReportsResultsToCSV (reportsResults);
    const finalResultsCSV = this.ConvertFinalResultsToCSV (finalResults);

    // ========= PAIRING FOLDER
    const pairage = zip.folder ('pairage');

    const version = pairage.folder ('version');
    const pairingInfos = {
      _id,
      pairing,
      pairingFilenameInUse: pairingFileName,
      results: {},
      finalResults: [],
      reportsResults: {},
    };
    version.file (`${pairingFileName}.json`, JSON.stringify (pairingInfos));

    const excel = pairage.folder ('excel');
    excel.file (`${pairingFileName}-projets.csv`, projectsCSV);
    excel.file (`${pairingFileName}-juges.csv`, judgesCSV);

    //=========== BACKUP RESULTS
    const resultats = zip.folder ('resultats');
    resultats.file ('DonneeClassement-1.csv', finalResultsCSV);

    const resultatsRapports = resultats.folder ('resultats_rapports');
    resultatsRapports.file (`notesRapportsEcrits.csv`, reportsResultsCSV);

    const resultatsInd = resultats.folder ('resultats_individuels');
    Object.keys (results).map (projectNumber => {
      Object.keys (results[projectNumber]).map (judgeNumber => {
        let indResultsCSV = this.ConvertIndividualResultsToCSV (
          results,
          projectNumber,
          judgeNumber
        );
        resultatsInd.file (indResultsCSV.name, indResultsCSV.csv);
      });
    });

    zip.generateAsync ({type: 'blob'}).then (content => {
      saveAs (content, `${zipname}.zip`);
    });
  };

  ConvertPairingToCSV = (pairing, obj = 'project', objToFind = 'judge') => {
    let csv = '';
    const header = `${obj};Periode A;Periode B;Periode C;Periode D;Periode E;Periode F;Periode G;Periode H;Periode I;\n`;

    csv += header;

    Object.keys (pairing).map (key => {
      let row = '';
      row += `${key};`;
      //Checks each period
      Object.keys (pairing[key]).map (period => {
        if (pairing[key][period][objToFind] !== undefined) {
          row += `${pairing[key][period][objToFind]};`;
        } else {
          row += ';';
        }
      });
      //End of row
      row += '\n';
      csv += row;
    });
    return csv;
  };

  ConvertReportsResultsToCSV = results => {
    let csv = '';
    const header = `NoProjet;NoteRap\n`;
    csv += header;
    Object.keys (results).map (key => {
      csv += `${results[key].project};${results[key].reportResult};\n`;
    });

    return csv;
  };

  ConvertIndividualResultsToCSV = (
    globalresults,
    projectNumber,
    judgeNumber
  ) => {
    const periodResults = globalresults[projectNumber][judgeNumber];
    const {results, period} = periodResults;
    const stringPeriod = String.fromCharCode (parseInt (period) + 64);

    let csv = '';
    const header = `Projet;Juge;Periode;Critere;Note;ValeurRelative;Total;TotalInternational;JugementComplet;\n`;
    csv += header;

    if (!isEmpty (results)) {
      Object.keys (results).map (criterion => {
        const criterionGroup = results[criterion];
        const {grade = null, total = null} = criterionGroup;

        csv += `${projectNumber};${judgeNumber};${stringPeriod};${criterion};${grade};${total};${periodResults.total};${periodResults.totalInternational};${periodResults.isComplete ? 'OUI' : 'NON'};\n`;
      });
    }

    const name = `projet ${projectNumber}/projet ${projectNumber} - periode ${stringPeriod} - juge ${judgeNumber}.csv`;
    return {name, csv};
  };

  ConvertFinalResultsToCSV = finalResults => {
    let csv = '';
    const header = `Rang;NoProjet;Note;Note inter;\n`;
    csv += header;

    finalResults.map ((res, index) => {
      const projectNumber = Object.keys (res)[0];
      csv += `${index + 1};${projectNumber};${res[projectNumber].finalAvgResults};${res[projectNumber].finalInternationalResults};\n`;
    });

    return csv;
  };

  DeleteFinal = () => {
    this.props.DeleteFinal (
      this.props.final.selectedFinal._id,
      this.props.history,
      this.props.auth.user.id,
      this.props.auth.user.isAdmin
    );
  };

  ToggleFinalActivation = () => {
    const finalId = this.props.match.params[0];
    this.props.ToggleFinalActivation (
      finalId,
      this.props.auth.user.isAdmin,
      this.props.auth.user.id
    );
  };

  RenderFinalInfos = () => {
    const {
      eventDate,
      location,
      longName,
      shortName,
      level,
      region,
      specialCharacter,
      isSuperExpo,
      judges,
      projects,
    } = this.props.final.selectedFinal;

    const {judgesList} = this.props.judge;
    const {projectsList} = this.props.project;

    const regionObject = Regions.find (r => {
      return r.id === region;
    });

    return (
      <div className="row py-3">
        {this.state.modal}
        <div className="col-md-12 mb-2">
          <h2>Informations sur la finale</h2>
        </div>

        <div className="col-md-6">
          <h5>
            <strong>Nom complet de la finale</strong>
          </h5>
          <p>{longName}</p>
        </div>

        <div className="col-md-6">
          <h5>
            <strong>
              Nom affiché sur appareils mobiles <br />(60 caractères max.)
            </strong>
          </h5>
          <p>{shortName}</p>
        </div>

        <div className="col-md-6">
          <h5>
            <strong>Date de l'événement</strong>
          </h5>
          <p>{moment (eventDate).format ('YYYY-MM-DD')}</p>
        </div>

        <div className="col-md-6">
          <h5>
            <strong>Lieu</strong>
          </h5>
          <p>{location}</p>
        </div>
        <div className="col-md-6">
          <h5>
            <strong>Région</strong>
          </h5>
          <p>
            {isSuperExpo
              ? 'Réseau Technoscience'
              : regionObject ? regionObject.name : ''}
          </p>
        </div>
        <div className="col-md-6">
          <h5>
            <strong>Volet</strong>
          </h5>
          <p>
            {isSuperExpo
              ? 'Finale québécoise'
              : level === 'highschool'
                  ? 'Volet secondaire/collégial'
                  : level === 'elementary'
                      ? 'Volet primaire'
                      : 'Erreur volet non défini'}
          </p>
        </div>

        <div className="col-md-12">
          <h5>
            <strong>
              Caractère de sécurité ajouté dans le mot de passe des juges
            </strong>
          </h5>
          <p>
            {specialCharacter}
          </p>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <button
              className="btn btn-main btn-block"
              onClick={this.ShowUpdateFinalModal}
            >
              Modifier les informations de la finale
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-6">
          <h5>
            <strong>
              Projets
            </strong>
          </h5>
          <p>
            {`${projectsList && projectsList.length} projets`}
          </p>
          <h5>
            <Link to={`/admin/finale/${this.props.match.params[0]}/projets`}>
              <span className="link">Voir la liste des projets</span>
            </Link>
          </h5>

        </div>
        <div className="col-md-6">
          <h5>
            <strong>
              Juges
            </strong>
          </h5>
          <p>
            {`${judgesList && judgesList.length} juges`}
          </p>
          <h5>
            <Link to={`/admin/finale/${this.props.match.params[0]}/juges`}>
              <span className="link">Voir la liste des juges</span>
            </Link>
          </h5>
        </div>
      </div>
    );
  };

  RenderActiveFinalSwitch = () => {
    const {isArchived, isActive} = this.props.final.selectedFinal;
    return (
      <div className="row pt-5">
        <div className="col-md-12 mb-2">
          <h2>
            État de la finale
          </h2>
          <h4>
            La finale est <strong>{isActive ? 'ACTIVE' : 'INACTIVE'}</strong>
            <button
              className="icon-button pl-3"
              onClick={this.ToggleFinalActivation}
            >
              {isActive
                ? <i className="fas fa-lg fa-toggle-on text-info" />
                : <i className="fas fa-lg fa-toggle-off text-muted" />}
            </button>
          </h4>

        </div>
      </div>
    );
  };

  RenderBackupButton = () => {
    return (
      <div>
        <div className="row py-2">
          <div className=" col-md-6 alert alert-info mx-auto text-center">
            <h2>Faire une archive de la finale</h2>
            <span>
              <h4>
                <i className="fas fa-archive pr-3" />
                L'archive contient:
              </h4>
              <ul>
                <li>Les informations sur l'événement</li>
                <li>La liste des projets</li>
                <li>La liste des juges</li>
                <li>La version en cours du pairage</li>
                <li>Le pairage par projets</li>
                <li>Le pairage par juges</li>
                <li>Les résultats individuels</li>
                <li>Le résultat final</li>
              </ul>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="btn btn-block" onClick={this.ArchiveFinal}>
              <span>
                <i className="fas fa-archive" />
              </span>
              &emsp; CRÉER UNE ARCHIVE
            </div>
          </div>
        </div>
      </div>
    );
  };

  RenderDeleteButton = () => {
    return (
      <div>
        <div className="row py-2">
          <div className="alert alert-danger mx-auto text-center">
            <h2>Supprimer la finale</h2>
            <span>
              <i className="fas fa-exclamation-triangle" />
              &emsp;Attention, toutes les données seront effacées définitivement!
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="btn btn-block" onClick={this.DeleteFinal}>
              <span>
                <i className="fas fa-trash" />
              </span>
              &emsp; EFFACER LA FINALE
            </div>
          </div>
        </div>
      </div>
    );
  };

  RenderFooter = () => {
    return (
      <footer className="text-center py-3 mt-5">
        L'application
        {' '}
        <strong>Jugement mobile</strong>
        {' '}
        a été développée par le
        {' '}
        <a
          href="http://technoscience.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          <em>Réseau Technoscience</em>
        </a>
        , &copy; 2019.
      </footer>
    );
  };

  RenderImportationModule = () => {
    return (
      <div className="col-md-6 py-3 mx-auto text-center">

        <div className="alert alert-success">
          <h2>Réimportation des informations de la finale</h2>
          <p>
            Attention, toutes les informations enregistrées seront écrasées et remplacées. En cas de doute, faites une exportation de la finale actuelle. Les jugements actuels seront remplacés par le fichier de l'importation.
          </p>
        </div>
        <label htmlFor="finalJsonImport" className="btn">
          <span>
            <i className="fas fa-undo" />
          </span>
          &emsp; IMPORTER LA FINALE
        </label>
        <input
          id="finalJsonImport"
          name="file"
          type="file"
          accept="application/json"
          multiple={false}
          onChange={this.OnFileSelect}
          style={{display: 'none'}}
        />
      </div>
    );
  };

  render () {
    const id = this.props.match.params[0];
    const final = this.props.final.selectedFinal;

    return (
      <Fragment>
        <FinalNav
          pageTitle="Finale - Infos"
          id={id}
          finalName={final.longName}
        />
        <div className="container ">
          {this.RenderActiveFinalSwitch ()}
          <hr />
          {this.RenderFinalInfos ()}
          <hr />
          {this.RenderBackupButton ()}
          <hr />
          {this.RenderImportationModule ()}
          <hr />
          {this.RenderDeleteButton ()}
        </div>
        {this.RenderFooter ()}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  final: state.final,
  judge: state.judge,
  project: state.project,
});
export default connect (mapStateToProps, {
  SelectFinalById,
  SelectProjectsByFinalId,
  GetJudgesPwd,
  DeleteAllFinalJudges,
  DeleteAllFinalProjects,
  DeleteFinal,
  ToggleFinalActivation,
  UpdateFinal,
  CreateJudge,
  CreateProject,
  UpdateFinal,
}) (FinalViewInfos);
