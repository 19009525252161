import React, { Component } from 'react';
import { connect } from 'react-redux';
import FinalNav from '../pages/partials/FinalNav';

import UpdateJudgeModal from './UpdateJudgeModal';
import FinalViewJudgesInfos from '../finals/finalPanels/FinalViewJudgesInfos';

import { SelectFinalById, CreateFinal } from '../../store/actions/finalActions';
import { SelectProjectsByFinalId } from '../../store/actions/projectActions';
import {
  GetJudgesPwd,
  UpdateJudge,
  AddNewJudge,
} from '../../store/actions/judgeActions';
import NewJudgeModal from './NewJudgeModal';
export class AdminJudgesList extends Component {
  constructor(props) {
    super(props);

    this.state = { modal: '' };
  }
  componentDidMount = () => {
    this.props.SelectFinalById(this.props.match.params[0]);
    //this.props.SelectProjectsByFinalId (this.props.match.params[0]);
    this.props.GetJudgesPwd(this.props.match.params[0]);
  };

  ReturnAccronym = (type, longName) => {
    let accronym = '';
    if (type === 'category') {
      switch (longName) {
        case 'human_social':
          accronym = 'SOC';
          break;
        case 'bio_health':
          accronym = 'BIO';
          break;
        case 'environment_ecosystems':
          accronym = 'ENV';
          break;
        case 'pure_sciences':
          accronym = 'PUR';
          break;
        case 'engineering_computer_robotics':
          accronym = 'ING';
          break;
        default:
          accronym = 'X';
          break;
      }
    } else if (type === 'type') {
      switch (longName) {
        case 'vulgarization':
          accronym = 'V';
          break;
        case 'experimentation':
          accronym = 'E';
          break;
        case 'conception':
          accronym = 'C';
          break;
        default:
          accronym = 'X';
          break;
      }
    } else if (type === 'classification') {
      switch (longName) {
        case 'Juvénile 1 (1er cycle du primaire)':
          accronym = 'P1';
          break;
        case 'Juvénile 2 (2e cycle du primaire)':
          accronym = 'P2';
          break;
        case 'Juvénile 3 (3e cycle du primaire)':
          accronym = 'P3';
          break;
        case 'Junior (secondaire 1)':
          accronym = 'J';
          break;
        case 'Intermédiaire (secondaire 2 et 3)':
          accronym = 'I';
          break;
        case 'Senior (secondaire 4 et 5)':
        case 'Senior (secondaire  4 et 5)':
          accronym = 'S';
          break;
        case 'Collégial 1 (1re et 2e année du cégep)':
          accronym = 'C1';
          break;
        case 'Collégial 2 (1re à la 3e année d’un programme technique du collégial ou une 3e année du double Dec)':
        case "Collégial 2 (1re à 3e année d'un programme technique du collégial ou une 3e année du double DEC)":
          accronym = 'C2';
          break;
        default:
          accronym = 'X';
          break;
      }
    }

    return accronym;
  };
  RenderLegend = () => {
    return (
      <div className="row main-legend mb-3">
        <div className="col-md-12 title">Légende</div>
        <div className="col-md-3 row">
          <div className="col-md-12 category title">Catégorie</div>
          <div className="col-md-12 category">
            {this.ReturnAccronym('category', 'bio_health')}
            - Sciences biologiques et sciences de la santé
          </div>
          <div className="col-md-12 category">
            {this.ReturnAccronym('category', 'pure_sciences')}
            - Sciences pures
          </div>
          <div className="col-md-12 category">
            {this.ReturnAccronym('category', 'human_social')}
            - Sciences sociales et réseaux sociaux
          </div>
          <div className="col-md-12 category">
            {this.ReturnAccronym('category', 'environment_ecosystems')}
            - Environnement et écosystèmes
          </div>
          <div className="col-md-12 category">
            {this.ReturnAccronym('category', 'engineering_computer_robotics')}
            - Ingénierie, informatique et robotique
          </div>

        </div>
        <div className="col-md-3 row">
          <div className="col-md-12 classification title">Classes</div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym(
              'classification',
              'Juvénile 1 (1er cycle du primaire)'
            )}
            - Juvénile 1 (1er cycle du primaire)
          </div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym(
              'classification',
              'Juvénile 2 (2e cycle du primaire)'
            )}
            - Juvénile 2 (2e cycle du primaire)
          </div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym(
              'classification',
              'Juvénile 3 (3e cycle du primaire)'
            )}
            - Juvénile 3 (3e cycle du primaire)
          </div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym('classification', 'Junior (secondaire 1)')}
            - Junior (secondaire 1)
          </div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym(
              'classification',
              'Intermédiaire (secondaire 2 et 3)'
            )}
            - Intermédiaire (secondaire 2 et 3)
          </div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym(
              'classification',
              'Senior (secondaire 4 et 5)'
            )}
            - Senior (secondaire  4 et 5)
          </div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym(
              'classification',
              'Collégial 1 (1re et 2e année du cégep)'
            )}
            - Collégial 1 (1re et 2e année du cégep)
          </div>
          <div className="col-md-12 classification">
            {this.ReturnAccronym(
              'classification',
              "Collégial 2 (1re à 3e année d'un programme technique du collégial ou une 3e année du double DEC)"
            )}
            - Collégial 2 (1re à 3e année d'un programme technique du collégial ou une 3e année du double DEC)
          </div>
        </div>
        <div className="col-md-3 row type">
          <div className="col-md-12 title">Type</div>
          <div className="col-md-12 type">
            {this.ReturnAccronym('type', 'vulgarization')}
            - Vulgarisation
          </div>
          <div className="col-md-12 type">
            {this.ReturnAccronym('type', 'experimentation')}
            - Experimentation
          </div>
          <div className="col-md-12 type">
            {this.ReturnAccronym('type', 'conception')}
            - Conception
          </div>
        </div>
        <div className="col-md-3 row ">
          <div className="col-md-12 title">Langue</div>
          <div className="col-md-12">
            Fr - Peut juger en français
          </div>
          <div className="col-md-12">
            En - Peut juger en anglais
          </div>
        </div>
      </div>
    );
  };
  RenderAddJudgeButton = () => {
    return (
      <div>
        <div className="row py-4">
          <div className="col-md-12">
            <h4>Ajouter un juge</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <button
              className="btn btn-block btn-main pl-3"
              onClick={this.ShowNewJudgeModal}
            >
              <span className="pr-2"><i className="fas fa-plus-circle" /></span>
              Ajouter un juge supplémentaire
            </button>
          </div>
        </div>
      </div>
    );
  };

  ShowNewJudgeModal = () => {
    const modal = (
      <NewJudgeModal
        ClearModal={this.ClearModal}
        AddNewJudge={this.props.AddNewJudge}
        finalId={this.props.match.params[0]}
        specialCharacter={this.props.final.selectedFinal.specialCharacter}
      />
    );

    this.setState({ modal }, () => {
      document.getElementById('modalJudge-btn').click();
    });
  };

  ShowUpdateModal = judge => {
    const modal = (
      <UpdateJudgeModal
        ClearModal={this.ClearModal}
        judge={judge}
        UpdateJudgeInfos={this.UpdateJudgeInfos}
      />
    );

    this.setState({ modal }, () => {
      document.getElementById('modalJudge-btn').click();
    });
  };

  UpdateJudgeInfos = judgeInfos => {
    this.props.UpdateJudge(judgeInfos);
    this.ClearModal();
  };

  ClearModal = () => {
    document.getElementById('closeModalBtn').click();
    this.setState({ modal: '' });
  };
  render() {
    const id = this.props.match.params[0];
    const final = this.props.final.selectedFinal;
    return (
      <div>
        <FinalNav
          pageTitle="Finale - Liste des juges"
          id={id}
          finalName={final.longName}
        />
        <div className="p-5">
          {this.state.modal}
          {this.RenderAddJudgeButton()}
          {/* PASSWORD LIST */}
          <div className="row pt-3">
            <div className="col-md-6">
              <h4>Liste des juges inscrits</h4>
            </div>
          </div>
          {this.RenderLegend()}
          <FinalViewJudgesInfos ShowUpdateModal={this.ShowUpdateModal} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  final: state.final,
});

const mapDispatchToProps = {
  SelectFinalById,
  SelectProjectsByFinalId,
  GetJudgesPwd,
  UpdateJudge,
  AddNewJudge,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminJudgesList);
